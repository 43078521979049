import { useMemo } from 'react';

import { baseTheme } from 'themes/baseTheme';
import { getTextWidth } from 'utils/functions/getTextWidth';

const MIN_WIDTH = 100;
const WIDTH_CALCULATION_PADDING = 4;

interface IUseInputWidthParams<T> {
  textInput: string;
  placeHolder?: string;
  fitContent: boolean;
  endAdornment?: JSX.Element;
  fontSize?: number;
  options?: T[];
  getOptionLabel?: (value: T) => string;
  maxInputWidth?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseInputWidthParams<T = any> = IUseInputWidthParams<T>;

export const useInputWidth = ({
  textInput,
  placeHolder,
  fitContent,
  endAdornment,
  fontSize = 16,
  options,
  getOptionLabel,
  maxInputWidth = 200,
}: UseInputWidthParams): { minInputWidth: number | null; optionsWidth: number | undefined } => {
  const minInputWidth = useMemo(() => {
    if (!fitContent) return null;

    const { fontFamily } = baseTheme.typography;

    if (!textInput && !placeHolder) {
      return MIN_WIDTH;
    }

    const inputText = textInput || '';
    let textWidth = getTextWidth(inputText, fontSize, fontFamily);

    if (!textInput && placeHolder) {
      textWidth = getTextWidth(placeHolder, fontSize, fontFamily);
    }

    const adornmentWidth = endAdornment ? endAdornment.props.style?.width || 0 : 0;
    return Math.ceil(textWidth + adornmentWidth);
  }, [fitContent, textInput, placeHolder, fontSize, endAdornment]);

  const optionsWidth = useMemo(() => {
    if (!fitContent || !options?.length) return undefined;

    const { fontFamily } = baseTheme.typography;

    const longestOption = options.reduce((longest, current) => {
      const currentLabel = getOptionLabel ? getOptionLabel(current) : String(current);
      const longestLabel = getOptionLabel ? getOptionLabel(longest) : String(longest);
      return currentLabel.length > longestLabel.length ? current : longest;
    }, options[0]);

    const longestLabel = getOptionLabel ? getOptionLabel(longestOption) : String(longestOption);
    return Math.min(maxInputWidth, getTextWidth(longestLabel, fontSize, fontFamily) + WIDTH_CALCULATION_PADDING);
  }, [options, getOptionLabel, fitContent, fontSize, maxInputWidth]);

  return { minInputWidth,
    optionsWidth };
};
