/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { useQuickGuideSectionComponents, rightStyle } from '../../hooks/useQuickGuideSectionComponents';

import styles from './QuickGuideSection.module.scss';

import { PanelLayout } from 'components/PanelLayout';
import { UICheck, Check, CheckStatus, BottomText } from 'pages/QuickStartGuide/types';
import { BaseTaskGuide } from 'pages/TeamsPortal/GuidePage/components/BaseTaskGuide/BaseTaskGuide';

interface Props {
  isLastSection: boolean;
  title: string;
  checks: string[];
  checksMap: Record<string, UICheck & Check>;
  bottomText?: BottomText;
}

export const QuickGuideSection: React.FC<Props> = ({ title, checks, checksMap, isLastSection, bottomText }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const visibleChecks = useMemo(
    () => checks.map((checkSlug) => checksMap[checkSlug]).filter((check) => check.isVisible),
    [checks, checksMap],
  );

  const isAllCompleted = useMemo(
    () => !!visibleChecks.length && visibleChecks.every((check) => check.status === CheckStatus.COMPLETED),
    [visibleChecks],
  );

  const isAtLeastOneSkipped = useMemo(
    () => visibleChecks.some((check) => check.status === CheckStatus.SKIPPED),
    [visibleChecks],
  );

  const isSectionHidden = useMemo(() => !visibleChecks.length, [visibleChecks]);
  const startsCollapsed = useMemo(() => isAllCompleted && !isLastSection, [isAllCompleted, isLastSection]);

  useEffect(() => {
    setIsExpanded(!isSectionHidden && !startsCollapsed);
  }, [isSectionHidden, isAllCompleted, startsCollapsed]);

  const toggleExpand = useCallback(() => {
    if (!isSectionHidden) {
      setIsExpanded((prev) => !prev);
    }
  }, [isSectionHidden]);

  const { rowComponents, bottomTextRowComponent } = useQuickGuideSectionComponents(
    isExpanded,
    title,
    isAllCompleted,
    bottomText,
    isAtLeastOneSkipped,
  );

  const toggleButtonClass = !isSectionHidden ? styles.toggleButton : '';

  if (isSectionHidden) return null;

  return (
    <div className={styles.sectionWrapper} data-testid='quick-guide-section'>
      <div className={toggleButtonClass} data-testid='quick-guide-button' onClick={toggleExpand} role='button' tabIndex={0}>
        <PanelLayout rowComponents={rowComponents} />
      </div>

      {isExpanded && (
        <div className={styles.checkList}>
          {visibleChecks.map((check) => (
            <div key={check.checkSlug} className={styles.checkItem} data-testid={`quick-guide-section-check-${check.checkSlug}`}>
              <BaseTaskGuide
                description={check.texts.description}
                isCompleted={check.status === CheckStatus.COMPLETED}
                MainComponent={check.mainUIComponent}
                RightComponent={check.statusUIComponent}
                styles={{ rightStyle }}
                title={check.texts.title}
              />

            </div>
          ))}
        </div>
      )}

      {bottomText && isExpanded && (
        <div className={styles.bottomPanel}>
          <PanelLayout
            rowComponents={bottomTextRowComponent}
          />
        </div>
      )}
    </div>
  );
};
