import { uniqBy } from 'lodash';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

import { IActiveToast } from './interfaces';

interface IToastsContext {
  activeToasts: IActiveToast[];
  showToast: (toast: IActiveToast) => void;
  hideToast: (index: number) => void;
}

export const ToastsContext = createContext<IToastsContext>({
  activeToasts: [],
  showToast: () => {},
  hideToast: () => {},
});

export const useToastsContext = () => useContext(ToastsContext);

export const ToastsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeToasts, setActiveToasts] = useState<IActiveToast[]>([]);

  const showToast = useCallback((toast: IActiveToast) => {
    setActiveToasts((curActiveToasts) => uniqBy([...curActiveToasts, toast], 'type'));
  }, []);

  const hideToast = useCallback((index: number) => {
    setActiveToasts((curActiveToasts) => curActiveToasts.filter((toast, i) => i !== index));
  }, []);

  const value = useMemo(() => ({
    activeToasts,
    showToast,
    hideToast,
  }), [activeToasts, hideToast, showToast]);

  return (
    <ToastsContext.Provider value={value}>
      {children}
    </ToastsContext.Provider>
  );
};
