import { FC } from 'react';

import styles from './TextAndLink.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { Link } from 'types/interfaces/Link/Link';
import { TextSize } from 'utils/constants/textSizeMap';

interface Text {
  text: string;
  size?: TextSize
  color?: string
}

interface Props {
  text: Text;
  link: Link;
  'data-testid'?: string
}

export const TextAndLink: FC<Props> = ({ text, link, ...props }) => (
  <div>
    <JitText className={styles.text} color={text.color} size={text.size} text={text.text} />

    <a
      className={styles.linkText}
      data-testid={props['data-testid'] || `link-${link.text}`}
      href={link.href}
      rel='noreferrer'
      target='_blank'
    >
      <div className={styles.linkContent}>

        <JitText
          color={colors.iris}
          size={text.size}
          text={link.text}
        />

        {link.icon && <JitIcon color={colors.iris} icon={link.icon} size={13} /> }

      </div>
    </a>

  </div>
);
