import { FC, useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import styles from './ChooseGroup.module.scss';
import { GroupsDropdown } from './GroupsDropdown';
import { SubgroupsDropdown } from './SubgroupsDropdown';

import { Alert, GitlabIcon, Info } from 'assets';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { IGroup, useGitlabService } from 'services/GitlabService/useGitlabService';
import colors from 'themes/colors.module.scss';
import { Queries } from 'types/enums/Queries';
import { GitlabAccessLevel } from 'types/interfaces';
import { useSnackBar } from 'utils/hooks/useSnackBar';

interface Props {
  onContinueButtonClick: (callback: () => Promise<boolean>) => void;
  selectedRole: GitlabAccessLevel;
  setSelectedGroup: (group: IGroup) => void;
  selectedGroup?: IGroup;
  setSelectedSubgroup: (group: IGroup) => void;
  selectedSubgroup?: IGroup;
  handleClose: () => void;
}

export interface ValidationError {
  status: number;
  action: string;
}

export const ChooseGroup: FC<Props> = ({
  onContinueButtonClick, selectedRole, setSelectedGroup, selectedGroup,
  setSelectedSubgroup, selectedSubgroup, handleClose,
}) => {
  const [validationError, setValidationError] = useState<ValidationError | undefined>(undefined);
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBar();
  const { offboardIntegration, createInstallation, createCentralizedProject } = useGitlabService();
  const [isResetButtonLoading, setIsResetButtonLoading] = useState(false);
  const [isNextButtonLoading, setIsNextButtonLoading] = useState(false);

  const onResetButtonClick = useCallback(async () => {
    setIsResetButtonLoading(true);
    const response = await offboardIntegration();
    if (response?.status !== 200) {
      showSnackBar({
        title: 'toasts.failedApiRequest.title',
        description: 'toasts.failedApiRequest.subtitle',
        type: 'error',
      });
    } else {
      await queryClient.invalidateQueries([Queries.TenantSecrets]);
      await queryClient.invalidateQueries([Queries.GitlabGroups]);
      await queryClient.invalidateQueries([Queries.GitlabSubgroups]);
      handleClose();
    }
    setIsResetButtonLoading(false);
  }, [offboardIntegration, handleClose, showSnackBar, queryClient]);

  const handleCreateInstallation = useCallback(async () => {
    if (!selectedGroup) return false;
    const res = await createInstallation({
      group_id: selectedGroup.id,
      group_name: selectedGroup.name,
      group_slug: selectedGroup.path,
      access_level: selectedRole,
    });
    if (res?.status !== 201 && res?.status !== 409) {
      setValidationError({ status: res?.status!,
        action: 'create installation' });
      return false;
    }
    return true;
  }, [selectedRole, createInstallation, selectedGroup]);

  const handleCreateCentralizedProject = useCallback(async () => {
    const res = await createCentralizedProject(selectedSubgroup ? { group_id: selectedSubgroup.id } : undefined);
    if (res?.status !== 200) {
      setValidationError({ status: res?.status!,
        action: 'create centralized project' });
      return false;
    }
    return true;
  }, [createCentralizedProject, selectedSubgroup]);

  const handleContinueButtonClick = useCallback(async () => {
    if (!selectedGroup) return false;
    setIsNextButtonLoading(true);

    const installation = await handleCreateInstallation();
    if (!installation) return false;

    const centralizedProject = await handleCreateCentralizedProject();
    if (!centralizedProject) return false;

    setIsNextButtonLoading(false);
    return true;
  }, [handleCreateCentralizedProject, handleCreateInstallation, selectedGroup]);

  const actionButton = useMemo(() => {
    if (validationError) {
      return {
        label: 'GitlabIntegrationWizard.chooseGitlabGroup.error.buttonText',
        disabled: isResetButtonLoading,
        handleClick: onResetButtonClick,
        isLoading: isResetButtonLoading,
      };
    }
    return {
      label: 'GitlabIntegrationWizard.chooseGitlabGroup.buttonText',
      disabled: !selectedGroup || isNextButtonLoading,
      handleClick: () => onContinueButtonClick(handleContinueButtonClick),
      isLoading: isNextButtonLoading,
    };
  }, [isResetButtonLoading, onContinueButtonClick, onResetButtonClick, selectedGroup, validationError, isNextButtonLoading, handleContinueButtonClick]);

  const banner = useMemo(() => {
    if (validationError) {
      return (
        <JitInfoBanner
          bgColor={colors.failRedBackground02}
          data-testid='error-banner'
          icon={Alert}
          iconColor={colors.error}
        >
          <JitText
            data-testid='banner-text'
            size='s'
            text={String(
              i18n.t('GitlabIntegrationWizard.chooseGitlabGroup.error.text', {
                action: validationError.action,
                reason: i18n.t(`GitlabIntegrationWizard.chooseGitlabGroup.error.${validationError.status}`, {
                  defaultValue: '',
                }),
              }),
            )}
          />
        </JitInfoBanner>
      );
    }

    return (
      <JitInfoBanner
        bgColor={colors.cardContent}
        data-testid='info-banner'
        icon={Info}
        iconColor={colors.blue}
      >
        <JitText
          data-testid='banner-text'
          size='s'
          text='GitlabIntegrationWizard.chooseGitlabGroup.info'
        />
      </JitInfoBanner>
    );
  }, [validationError]);

  return (
    <DialogContentWrapper
      actionButtons={[actionButton]}
      icon={GitlabIcon}
      leftButtonsElement={(
        <JitExternalLink
          href={i18n.t(`GitlabIntegrationWizard.chooseGitlabGroup.docsReference.link.${selectedRole}`)}
          text='GitlabIntegrationWizard.chooseGitlabGroup.docsReference.text'
        />
          )}
    >
      <div className={styles.content}>
        <div className={styles.dropdowns}>
          <GroupsDropdown
            isButtonLoading={isNextButtonLoading || isResetButtonLoading}
            minAccessLevel={selectedRole}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            setValidationError={setValidationError}
            validationError={validationError}
          />

          <SubgroupsDropdown
            groupId={selectedGroup?.id}
            isButtonLoading={isNextButtonLoading || isResetButtonLoading}
            minAccessLevel={selectedRole}
            selectedSubgroup={selectedSubgroup}
            setSelectedSubgroup={setSelectedSubgroup}
            setValidationError={setValidationError}
          />
        </div>

        {banner}

      </div>
    </DialogContentWrapper>
  );
};
