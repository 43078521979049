import { FC, useMemo } from 'react';

import { PriorityScore } from 'assets';
import { IconTextBox } from 'components/IconTextBox/IconTextBox';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { calcPriorityScoreColor } from 'pages/FindingsPage/components/PriorityBox/utils';
import colors from 'themes/colors.module.scss';
import { sizesMap, TextSize } from 'utils/constants/textSizeMap';

interface Props {
  priorityScore: number;
  withBorder?: boolean;
  disabled?: boolean;
  dynamicColor?: boolean;
  size?: TextSize;
  iconSize?: TextSize;
  bold?: boolean;
}

export const PriorityBox: FC<Props> = ({ priorityScore, withBorder = true, disabled, dynamicColor = true, size = 's', iconSize = size, bold = true }) => {
  let color = dynamicColor ? calcPriorityScoreColor(priorityScore) : colors.criticalPriority;
  const isNegative = priorityScore < 0;
  if (isNegative) {
    color = colors.lightGray;
  }
  if (disabled) {
    color = colors.gray;
  }

  const icon = useMemo(() => (
    <JitIcon color={color} icon={PriorityScore} rotateDegrees={isNegative ? 180 : 0} size={sizesMap[iconSize]} />
  ), [color, isNegative, iconSize]);

  return (
    <IconTextBox bold={bold} color={color} icon={icon} size={size} testId='priorityBox' text={Math.abs(priorityScore)} withBorder={withBorder} />
  );
};
