import Markdown from 'markdown-to-jsx';
import { FC } from 'react';

import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { markdownOptionsProps } from 'utils/constants/markdownOptionsProps';
import { TextSize } from 'utils/constants/textSizeMap';

interface Props {
  headerClassName: string
  headerColor: string
  headerSize: TextSize
  headerText: string
  linkUrl?: string
  bodyText: string
  isMarkdown?: boolean
}

export const FindingSectionHeader: FC<Props> = ({ headerClassName, headerColor, headerSize, headerText, linkUrl, bodyText, isMarkdown }) => {
  const text = isMarkdown ? <Markdown {...markdownOptionsProps}>{bodyText}</Markdown> : <JitText color={linkUrl && colors.iris} text={bodyText} />;
  return (
    <>
      <JitText className={headerClassName} color={headerColor} size={headerSize} text={headerText} />

      {linkUrl ? (
        <JitLink
          href={linkUrl}
          rel='noreferrer'
          sx={{
            cursor: 'pointer',
            width: 'fit-content',
          }}
          target='_blank'
        >

          {text}
        </JitLink>
      ) : text}
    </>
  );
};
