import { useMemo, useState } from 'react';

import { useChooseGitlabProjectsStep } from './useChooseGitlabProjectsStep';
import { useChooseGroupStep } from './useChooseGroupStep';
import { useChooseIntegrationMethodStep } from './useChooseIntegrationMethodStep';
import { useConnectWebhooksStep } from './useConnectWebhooksStep';
import { useCreateMaintainerTokenStep } from './useCreateTokenStep';
import { useIntegrateGitlabStep } from './useIntegrateGitlabStep';
import { useRunnerTypeSelectionStep } from './useRunnerTypeSelectionStep';
import { useVerifyPermissionsStep } from './useVerifyPermissionsStep';

import { IGroup } from 'services/GitlabService/useGitlabService';
import { GitlabAccessLevel } from 'types/interfaces';

interface Props {
  incrementStepIndex: () => void;
  handleClose: () => void;
}

export const useWizardSteps = ({ incrementStepIndex, handleClose }: Props) => {
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();
  const [selectedRole, setSelectedRole] = useState<GitlabAccessLevel>(GitlabAccessLevel.OWNER);

  const integrateGitlabStep = useIntegrateGitlabStep({
    incrementStepIndex,
  });

  const verifyPermissionsStep = useVerifyPermissionsStep({
    incrementStepIndex,
    handleClose,
  });

  const chooseIntegrationMethodStep = useChooseIntegrationMethodStep({
    incrementStepIndex,
    selectedRole,
    setSelectedRole,
  });

  const runnerTypeSelectionStep = useRunnerTypeSelectionStep({ handleClose });

  const createTokenStep = useCreateMaintainerTokenStep({ incrementStepIndex });

  const chooseGroupStep = useChooseGroupStep({
    incrementStepIndex,
    selectedGroup,
    setSelectedGroup,
    selectedRole,
    handleClose,
  });

  const connectWebhooksStep = useConnectWebhooksStep({ incrementStepIndex });

  const chooseGitlabProjectsStep = useChooseGitlabProjectsStep({
    selectedGroup,
    selectedRole,
    incrementStepIndex,
  });

  const steps = useMemo(() => {
    const sharedSteps = [verifyPermissionsStep, chooseIntegrationMethodStep];

    if (selectedRole === GitlabAccessLevel.OWNER) {
      return [...sharedSteps, integrateGitlabStep, chooseGroupStep, chooseGitlabProjectsStep, runnerTypeSelectionStep];
    }

    return [...sharedSteps, createTokenStep, chooseGroupStep, connectWebhooksStep, chooseGitlabProjectsStep, runnerTypeSelectionStep];
  }, [
    verifyPermissionsStep,
    chooseIntegrationMethodStep,
    selectedRole,
    createTokenStep,
    chooseGroupStep,
    connectWebhooksStep,
    chooseGitlabProjectsStep,
    integrateGitlabStep,
    runnerTypeSelectionStep,
  ]);

  return steps;
};
