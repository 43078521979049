import { FC, useMemo, useState, useCallback, useEffect, useRef, PropsWithChildren } from 'react';

import { GetSbomComponents, SbomContext } from 'context/SbomContext/SbomContext';
import { useSubscribeToSbomStatusWebsocket } from 'context/SbomContext/useSubscribeToSbomStatusWebsocket';
import { PRODUCT_CATALOG_FEATURES } from 'context/StiggContext/constants';
import { useGetBooleanEntitlement } from 'context/StiggContext/useGetBooleanEntitlement';
import { logError } from 'services/logger/logger';
import { useSbomService } from 'services/SbomService/useSbomService';
import { IPaginatedState, initialPaginatedState } from 'types/interfaces/IPaginatedState';
import { ExpendableComponentGroup } from 'types/interfaces/Sbom/SbomComponentGroup';
import { SbomReportStatus } from 'types/interfaces/Sbom/SbomReportStatus';

export const SbomProvider: FC<PropsWithChildren> = ({ children }) => {
  const [reportStatus, setReportStatus] = useState<SbomReportStatus>();
  const [isLoadingReportStatus, setIsLoadingReportStatus] = useState<boolean>(false);
  const [sbomComponents, setSbomComponents] = useState<IPaginatedState<ExpendableComponentGroup>>(initialPaginatedState);
  const { getSbomComponentGroups, getSbomReportStatus } = useSbomService();
  const getBooleanEntitlement = useGetBooleanEntitlement();
  const lastRequestId = useRef<number>();

  const isPremiumFeatureEnabled = useMemo(() => getBooleanEntitlement(PRODUCT_CATALOG_FEATURES.SBOM, true), [getBooleanEntitlement]);

  const getReportStatus = useCallback(async () => {
    setIsLoadingReportStatus(true);
    const response = await getSbomReportStatus();
    if (response?.status === 200) {
      setReportStatus(response.data.status);
    } else {
      logError('Failed to fetch sbom feature status', {
        status: response?.status,
        data: response?.data,
      });
    }
    setIsLoadingReportStatus(false);
  }, [getSbomReportStatus]);

  const getSbomComponents: GetSbomComponents = useCallback(async ({ resetPagination, searchValue, silent }) => {
    const currentRequestIds = Date.now();
    lastRequestId.current = currentRequestIds;
    setSbomComponents((prevState) => ({
      ...prevState,
      isLoading: !silent,
      data: resetPagination ? [] : prevState.data,
    }));
    const limit = isPremiumFeatureEnabled ? 20 : 12;
    const after = resetPagination ? undefined : sbomComponents.after;
    const response = await getSbomComponentGroups(after, limit, searchValue);
    if (lastRequestId.current !== currentRequestIds) return; // Ignore old requests
    if (response?.status === 200) {
      setSbomComponents((prevState) => ({
        ...prevState,
        data: [...prevState.data, ...response.data.data],
        hasReachedEnd: !response.data.metadata.after,
        isLoading: false,
        after: response.data.metadata.after,
      }));
    } else {
      logError('Failed to fetch sbom components', {
        status: response?.status,
        data: response?.data,
      });
      setSbomComponents((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }, [getSbomComponentGroups, isPremiumFeatureEnabled, sbomComponents.after]);
  useSubscribeToSbomStatusWebsocket(reportStatus, setReportStatus, getSbomComponents);

  const toggleComponentExpended = useCallback((groupId: string) => {
    setSbomComponents((prevState) => ({
      ...prevState,
      data: prevState.data.map((componentGroup) => {
        if (componentGroup.id === groupId) {
          return {
            ...componentGroup,
            expanded: !componentGroup.expanded,
          };
        }
        return componentGroup;
      }),
    }));
  }, []);

  useEffect(() => {
    getReportStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only run on mount

  const value = useMemo(() => ({
    sbomComponents,
    setSbomComponents,
    getSbomComponents,
    toggleComponentExpended,
    reportStatus,
    isLoadingReportStatus,
    isPremiumFeatureEnabled,
    getReportStatus,
  }), [sbomComponents, getSbomComponents, toggleComponentExpended, reportStatus, isLoadingReportStatus, isPremiumFeatureEnabled, getReportStatus]);

  return (
    <SbomContext.Provider value={value}>
      {children}
    </SbomContext.Provider>
  );
};
