import { Interval } from 'types/types';
import { setIntervalImmediately } from 'utils';

interface StyleConfig {
  [key: string]: string;
}

const getFronteggContainer = () => {
  const container = document.querySelector('#frontegg-login-box-container-default');
  return container?.shadowRoot || null;
};

const getFronteggElement = (selector: string): HTMLElement | null => {
  const container = getFronteggContainer();
  return container?.querySelector(selector) || null;
};

const getDirectElement = (selector: string): HTMLElement | null => {
  const container = document.querySelector('#frontegg-login-box-container-default');
  return container?.querySelector(selector) || null;
};

const updateElementStyle = (element: HTMLElement, styles: StyleConfig): void => {
  const newElement = element;
  Object.assign(newElement.style, styles);
};

const updateElementContent = (element: HTMLElement, content: string): void => {
  const newElement = element;
  newElement.innerHTML = content;
};

const updateElementWithRetry = (
  selector: string,
  updateFn: (element: HTMLElement) => void,
  interval = 100,
  maxAttempts = 10,
  useDirectQuery = false,
): Interval => setIntervalImmediately(() => {
  const element = useDirectQuery
    ? getDirectElement(selector)
    : getFronteggElement(selector);

  if (element) {
    updateFn(element);
    return true;
  }
  return false;
}, interval, maxAttempts);

// Specific element updates
const updateTitle = (element: HTMLElement) => {
  updateElementStyle(element, {
    fontWeight: '600',
    fontSize: '1.7rem',
    marginBottom: '0',
  });
};

const updateDescription = (element: HTMLElement): void => {
  updateElementStyle(element, {
    fontFamily: 'Sora',
    fontWeight: '400',
    lineHeight: '1.4',
    fontSize: '1rem',
  });

  updateElementContent(element, `
      For seamless collaboration and enhanced security, 
      please sign in with your company email.
  `);
};

const updateTerms = (element: HTMLElement): void => {
  updateElementStyle(element, {
    fontFamily: 'Sora',
    fontWeight: '400',
    lineHeight: '1.4',
    fontSize: '1rem',
    color: 'rgb(113, 127, 148)',
    textDecoration: 'none',
  });

  updateElementContent(element, `Don't have a company email? <br/>
    <a href="mailto:contact@jit.io" style="color: rgb(77, 125, 250); text-decoration: none; cursor: pointer">
      Contact us
    </a> and we'll be happy to assist you.`);
};

const hidePoweredByFrontegg = (element: HTMLElement) => {
  // eslint-disable-next-line no-param-reassign
  element.style.display = 'none';
};

export const initializeFronteggUI = () => {
  const intervals: Interval[] = [];

  intervals.push(
    updateElementWithRetry('[data-test-id="powered-by-logo"]', hidePoweredByFrontegg),
    updateElementWithRetry('[data-test-id="social-login-failure-description"]', updateDescription),
    updateElementWithRetry('[data-test-id="social-login-failure-title"]', updateTitle),
    updateElementWithRetry('[data-testid="By continuing you agree to our<br><termsOfService/> and <privacyPolicy/>."]', updateTerms, 10, 1, true),
  );

  return () => intervals.forEach(clearInterval);
};
