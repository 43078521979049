
import { FC } from 'react';

import styles from './InactiveState.module.scss';

import { PremiumLock, SBOMInactiveState } from 'assets';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitUpgradeButton } from 'components/JitUpgradeButton/JitUpgradeButton';
import { Background } from 'components/Pages/Background/Background';
import { PremiumFeatureBanner } from 'components/PremiumFeatureBanner/PremiumFeatureBanner';
import { config } from 'config';
import { useSbomContext } from 'context/SbomContext/SbomContext';
import { useGetCustomerTrialDetails } from 'context/StiggContext/useGetCustomerTrialDetails';
import { useActivateSBOM } from 'pages/SbomPage/hooks/useActivateSBOM';

export const InactiveState: FC = () => {
  const { isOnTrial, hasPaidSubscription } = useGetCustomerTrialDetails();
  const {
    isPremiumFeatureEnabled,
  } = useSbomContext();

  const canActivate = hasPaidSubscription || isOnTrial;

  const i18nKey = canActivate ? 'pages.sbom.inactiveState.activate' : 'pages.sbom.inactiveState.upgrade';

  const [activate, isActivating] = useActivateSBOM();

  return (
    <div className={styles.wrapper}>

      {!hasPaidSubscription && isOnTrial && <PremiumFeatureBanner isEntitled={isPremiumFeatureEnabled} />}

      <div className={styles.content}>
        <SBOMInactiveState />

        <div className={styles.mainText}>
          <div className={styles.titleWrapper}>
            {!canActivate && <JitIcon icon={PremiumLock} />}

            <JitText bold size='xxl' text={`${i18nKey}.title`} textAlign='center' />
          </div>

          <JitText muted size='m' text={`${i18nKey}.text`} textAlign='center' />
        </div>

        {canActivate ? (
          <JitGradientButton className={styles.activateButton} isLoading={isActivating} onClick={activate} showChildrenWhileLoading>
            <JitText bold text={`${i18nKey}.cta`} />
          </JitGradientButton>
        ) : <JitUpgradeButton iconFontSize='l' text='pages.sbom.table.paywallGradiant.upgrade' textSize='s' />}
      </div>

      {!canActivate && <JitExternalLink href={config.contactUsUrl} text='pages.sbom.inactiveState.contactUs' />}

      <div className={styles.spacer} />

      <Background />
    </div>
  );
};
