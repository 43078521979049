import { useGithubService } from '../GithubService/useGithubService';
import { useGitlabService } from '../GitlabService/useGitlabService';

import { logError } from 'services/logger/logger';
import { SCMVendors, Vendor } from 'types/enums/Vendor';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { FetchMembersParams } from 'types/interfaces/SCM/SCMMember';

type SCMVendorService = typeof useGithubService | typeof useGitlabService;

const scmVendorServices: Record<SCMVendors, SCMVendorService> = {
  [Vendor.Github]: useGithubService,
  [Vendor.GITLAB]: useGitlabService,
};

export const useSCMService = (vendor: SCMVendors) => {
  const service = scmVendorServices[vendor]?.();

  const getMembers = async (params: FetchMembersParams): Promise<IPaginatedResponse<string>> => {
    if (!service) {
      logError(`SCM vendor service not found for vendor: ${vendor}`);
      return {
        data: [],
        metadata: {
          count: 0,
          limit: params.limit || 0,
        },
      };
    }

    return service.getMembers(params);
  };

  return { getMembers };
};
