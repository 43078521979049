import { FC, useCallback } from 'react';

import { useGetColumns } from './useGetColumns';
import styles from './WorkflowHistoryTable.module.scss';

import { JittyNoWorkflow } from 'assets';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowRun } from 'types/interfaces/Workflows/IWorkflowRun';

interface WorkflowHistoryTableProps {
  runs: IWorkflowRun[];
  workflow?: IWorkflow;
  isFetchingNextPage?: boolean;
  fetchNextPage: () => void;
}

export const WorkflowHistoryTable: FC<WorkflowHistoryTableProps> = ({
  runs,
  workflow,
  fetchNextPage,
  isFetchingNextPage,
}) => {
  const { columns } = useGetColumns(workflow);
  const tableInstance = useGetTableInstance(columns, runs);

  const handleFetchNextPage = useCallback(() => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetchingNextPage]);

  return (
    <div className={styles.tableWrapper} data-testid='workflow-history-table'>
      <JitTable
        emptyTableSubtext='pages.workflows.editWorkflowPage.historyView.table.emptySubtext'
        emptyTableText='pages.workflows.editWorkflowPage.historyView.table.emptyText'
        handleReachScrollEnd={{
          callback: handleFetchNextPage,
        }}
        isFetching={isFetchingNextPage}
        isLoading={false}
        jittyHeight='150px'
        jittyIcon={JittyNoWorkflow}
        jittyWidth='154px'
        showPagination={false}
        tableInstance={tableInstance}
      />
    </div>
  );
};
