/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useQuickstartGuide } from '../../hooks/useQuickstartGuide';
import styles from '../ButtonMainComponent.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import { CONFIGURE_TEAMS_CHECK_SLUG } from 'context/TenantContext/constant';
import { constants } from 'globalConstants';
import { CheckStatus } from 'pages/QuickStartGuide/types';
import { POST_INSTALL_QUERY_PARAM } from 'pages/TeamsPage/constants';
import { InviteMembersDialog } from 'pages/TeamsPage/SubPages/TeamsPage/components/InviteMembersDialog/InviteMembersDialog';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';

export const ManageTeamsButton: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { checksMap } = useQuickstartGuide();
  const { setCheckAsCompleted, isIntegrationsLoading } = useTenantContext();
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [hasProcessedQueryParam, setHasProcessedQueryParam] = useState(false);

  const isTeamsConfigurationComplete = useMemo(() => {
    const teamsConfigurationCheck = checksMap[CONFIGURE_TEAMS_CHECK_SLUG];
    return teamsConfigurationCheck?.status === CheckStatus.COMPLETED;
  }, [checksMap]);

  useEffect(() => {
    if (!hasProcessedQueryParam && !isIntegrationsLoading) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.get(POST_INSTALL_QUERY_PARAM) === Vendor.SLACK) {
        setIsInviteDialogOpen(true);
      }
      setHasProcessedQueryParam(true);
    }
  }, [location, navigate, hasProcessedQueryParam, isIntegrationsLoading]);

  const handleClick = useCallback(() => {
    if (!isTeamsConfigurationComplete) {
      setCheckAsCompleted(CONFIGURE_TEAMS_CHECK_SLUG);
      navigate(`/${constants.routes.TEAMS}`);
    } else {
      setIsInviteDialogOpen(true);
    }
  }, [isTeamsConfigurationComplete, navigate, setCheckAsCompleted]);

  const handleOpenDialog = useCallback(() => setIsInviteDialogOpen(false), []);

  if (isIntegrationsLoading && isTeamsConfigurationComplete) {
    return <CircularLoadingSpinner size='small' variant='colorful' />;
  }

  return (
    <>
      {isIntegrationsLoading && isTeamsConfigurationComplete ? (
        <CircularLoadingSpinner size='small' variant='colorful' />
      ) : isTeamsConfigurationComplete ? (
        <JitText
          color={colors.iris}
          onClick={handleClick}
          text='pages.quickStart.checks.configureTeams.inviteEngineeringManagersButton'
        />
      ) : (
        <JitGradientButton onClick={handleClick}>
          <div className={styles.buttonContent}>
            <JitText bold fontFamily='Inter, serif' size='s' text='pages.quickStart.checks.configureTeams.configureButton' />
          </div>
        </JitGradientButton>
      )}

      <InviteMembersDialog
        isOpen={isInviteDialogOpen}
        onClose={handleOpenDialog}
      />
    </>
  );
};
