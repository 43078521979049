import { ReactElement } from 'react';

import styles from '../common/TokenInput.module.scss';

import { JitText } from 'components/JitText/JitText';

interface CollapsibleStep {
  stepId: string;
  titleComponent: ReactElement;
  bodyComponent: ReactElement;
}

interface FormField {
  fieldText: string;
  valueText: string;
}

const getFormFields = (tenantId: string, installationId: string): FormField[] => [
  {
    fieldText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.urlField',
    valueText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.urlValue',
  },
  {
    fieldText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.headerName',
    valueText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.tenantIdHeaderNameValue',
  },
  {
    fieldText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.headerValue',
    valueText: tenantId,
  },
  {
    fieldText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.headerName',
    valueText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.installationIdHeaderNameValue',
  },
  {
    fieldText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.headerValue',
    valueText: installationId,
  },
  {
    fieldText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.secretTokenField',
    valueText: 'GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.secretTokenFieldValue',
  },
];

export const getCollapsibleSteps = (tenantId: string, installationId: string): CollapsibleStep[] => {
  const formFields = getFormFields(tenantId, installationId);

  return [
    {
      stepId: 'step-1',
      titleComponent: <JitText
        components={{
          addNewWebhook: <JitText bold display='inline' text='GitlabIntegrationWizard.connectWebhooks.steps.pressAddNewToken.titleBold' />,
        }}
        muted
        text='GitlabIntegrationWizard.connectWebhooks.steps.pressAddNewToken.title'
      />,
      bodyComponent: (
        <div className={styles.expandedContent}>
          <JitText muted text='GitlabIntegrationWizard.connectWebhooks.steps.pressAddNewToken.body1' />

          <JitText muted text='GitlabIntegrationWizard.connectWebhooks.steps.pressAddNewToken.body2' />
        </div>
      ),
    },
    {
      stepId: 'step-2',
      titleComponent: <JitText
        components={{
          formName: <JitText bold display='inline' text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.titleBold' />,
        }}
        muted
        text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.title'
      />,
      bodyComponent: (
        <div className={styles.expandedContent}>
          <div className={styles.wrapper} data-testid='formTable'>

            <JitText muted size='s' text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.guidelines' />

            <div className={styles.row}>
              <JitText horizontalAlign='start' muted size='m' text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.fieldColumnHeader' />

              <JitText horizontalAlign='start' muted size='m' text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.valueColumnHeader' />
            </div>

            {formFields.map((field) => (
              <div key={field.fieldText} className={styles.row}>
                <JitText text={field.fieldText} />

                <JitText text={field.valueText} />
              </div>
            ))}

            <div className={styles.row}>
              <JitText text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.triggerField' />

              <JitText
                components={{
                  triggerValueChoose: <JitText text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.triggerValue.choose' />,
                  triggerValuePushEvents: <JitText text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.triggerValue.pushEvents' />,
                  triggerValueComments: <JitText text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.triggerValue.comments' />,
                  triggerValueSubgroupEvents: <JitText text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.triggerValue.subgroupEvents' />,
                  triggerValueProjectEvents: <JitText text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.triggerValue.projectEvents' />,
                  triggerValueMergeRequestEvents: <JitText text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.triggerValue.mergeRequestEvents' />,
                  triggerValueDeploymentEvents: <JitText text='GitlabIntegrationWizard.connectWebhooks.steps.fillTheForm.triggerValue.deploymentEvents' />,
                }}
                text={`
                  <triggerValueChoose/> <br/>
                  <triggerValuePushEvents/>
                  <triggerValueComments/>
                  <triggerValueSubgroupEvents/>
                  <triggerValueProjectEvents/>
                  <triggerValueMergeRequestEvents/>
                  <triggerValueDeploymentEvents/>
                `}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];
};
