import { Link } from 'react-router-dom';

import styles from '../JitBreadcrumbs.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { TextSize } from 'utils/constants/textSizeMap';

export const renderTextBreadCrumbItem = (
  text: string,
  dataTestId?: string,
  size?: TextSize,
  withLinkColor?: boolean,
) => (
  <JitText
    bold
    color={withLinkColor ? colors.lightBlue : undefined}
    data-testid={dataTestId || 'BreadcrumbItem'}
    size={size || 'l'}
    text={text}
  />
);

export const renderLinkBreadCrumbItem = (
  text: string,
  link?: string,
  dataTestId?: string,
) => (
  <Link
    className={styles.breadcrumbsLink}
    data-testid={dataTestId ? `${dataTestId}-link` : `${text}-link`}
    to={link || '/'}
  >
    {renderTextBreadCrumbItem(text, dataTestId, 'l', true)}
  </Link>
);
