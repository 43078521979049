import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { OpenInNewTab } from 'assets';
import { DialogCard } from 'components/JitDialogs/DialogCard/DialogCard';
import { config } from 'config';
import { useIntegrationsTemplate } from 'context/IntegrationsContext/templates/useIntegrationTemplates';
import { useAddSecretDialog } from 'pages/IntegrationsPage/hooks/useAddSecretDialog/useAddSecretDialog';
import { IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';
import { Link } from 'types/interfaces/Link/Link';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

interface Props {
  setIsIntegrationComplete: Dispatch<SetStateAction<boolean>>;
  dialogCardWidth?: string;
  vendorKeyForTemplate: string;
}

export const SecretIntegration: FC<Props> = ({ dialogCardWidth, setIsIntegrationComplete, vendorKeyForTemplate }) => {
  const { getTemplateByVendorKey } = useIntegrationsTemplate();
  const integrationTemplate = getTemplateByVendorKey(vendorKeyForTemplate);
  const { action, component, validation } = useAddSecretDialog(integrationTemplate);
  const { getIconByVendor } = useDetailsByVendor();
  const vendorIcon = getIconByVendor(vendorKeyForTemplate) || undefined;
  const integrationStatus = validation(integrationTemplate?.options?.fields.name.value);
  const isIntegrated = integrationStatus === IntegrationStatus.SUCCESS;

  useEffect(() => {
    setIsIntegrationComplete(isIntegrated);
  }, [setIsIntegrationComplete, isIntegrated]);

  const integrationDocumentationLink: Link = {
    text: integrationTemplate?.link?.text || 'dialogs.integration.secrets.learn_more',
    href: integrationTemplate?.link?.href || config.docsUrl,
    icon: OpenInNewTab,
  };

  const integrationShortDescription = integrationTemplate?.shortDescription || 'dialogs.integration.secrets.guidelines';

  return (
    <div data-testid='SecretIntegration'>
      <DialogCard
        configureText='configurations.integrations.configure'
        description={integrationShortDescription}
        icon={vendorIcon}
        isConnected={isIntegrated}
        link={integrationDocumentationLink}
        onConfigure={action}
        onConnect={action}
        vendor={vendorKeyForTemplate}
        width={dialogCardWidth}
      />

      {component}
    </div>
  );
};
