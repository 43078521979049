import Skeleton from '@mui/material/Skeleton';
import { CSSProperties, FC } from 'react';

import colors from 'themes/colors.module.scss';

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const LoadingSkeleton: FC<Props> = ({ className, style }) => (
  <div className={className} data-testid='loading-skeleton'>
    <Skeleton
      animation='wave'
      data-testid='skeleton'
      style={style}
      sx={{
        transform: 'scale(1, 1)',
        bgcolor: colors.cardContent,
      }}
    />
  </div>
);
