import { useMemo } from 'react';

import {
  IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus,
} from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { ChooseGitlabProjects } from 'components/GitlabIntegrationWizard/StepComponents/ChooseGitlabProjects/ChooseGitlabProjects';
import { useTenantContext } from 'context/TenantContext';
import { IGroup } from 'services/GitlabService/useGitlabService';
import { GitlabAccessLevel, IGitlabInstallationVendorAttributes } from 'types/interfaces';

interface Props {
  selectedGroup?: IGroup;
  selectedRole: GitlabAccessLevel;
  incrementStepIndex: () => void;
}

export const useChooseGitlabProjectsStep = ({ selectedGroup, selectedRole, incrementStepIndex }: Props): IGitlabIntegrationWizardStep => {
  const { gitlabInstallation } = useTenantContext();

  const status = useMemo(() => {
    const vendorAttributes = gitlabInstallation?.vendor_attributes as IGitlabInstallationVendorAttributes;
    if (vendorAttributes?.repo_selection) return IGitlabIntegrationWizardStepStatus.COMPLETE;
    return IGitlabIntegrationWizardStepStatus.ACTIVE;
  }, [gitlabInstallation]);

  return useMemo(() => ({
    title: 'GitlabIntegrationWizard.chooseGitlabProjects.tabText',
    component: () => (
      selectedGroup ? (
        <ChooseGitlabProjects
          incrementStepIndex={incrementStepIndex}
          selectedGroup={selectedGroup}
          selectedRole={selectedRole}
          status={status}
        />
      ) : null
    ),
    status,
  }), [selectedGroup, selectedRole, status, incrementStepIndex]);
};
