import { FC, ReactElement } from 'react';

import { CallbackProps } from 'pages/PoliciesPage/components/PolicyBuilder/hooks/useOptionsQueries';
import { CallbackKey } from 'pages/PoliciesPage/types';
import { getSelectedOptionValues } from 'pages/PoliciesPage/utils';

interface ConditionItemProps {
  rule: ReactElement;
  activateCallback: (callback: CallbackKey) => void;
  getCallbackProps: (callback: CallbackKey) => CallbackProps;
}

export const BaseConditionItem: FC<ConditionItemProps> = ({ rule, activateCallback, getCallbackProps }) => {
  const Component = rule?.type;
  if (!Component) return null;

  const { optionsCallback, defaultValue, inputValue, multi, ...restInputProps } = rule?.props || {};

  if (optionsCallback) {
    activateCallback(optionsCallback);
  }

  const callbackProps = {
    ...restInputProps,
    ...(optionsCallback && getCallbackProps(optionsCallback)),
    maxInputWidth: 150,
  };

  const selectedValue = getSelectedOptionValues({
    options: callbackProps.options,
    defaultValue,
    inputValue,
    multi,
  });

  const inputProps = {
    ...callbackProps,
    selectedValue,
    isLoading: callbackProps.disabled ? false : callbackProps.isLoading,
  };

  return <Component key={rule.key} {...inputProps} />;
};
