import { IconButton } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';

import styles from './CoverageSelectionHeader.module.scss';

import { Cross } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

interface Props {
  onClose: () => void;
}

export const CoverageSelectionHeader = ({ onClose }: Props) => (
  <DialogTitle className={styles.dialogTitle}>
    <JitText bold size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.title' />

    <div className={styles.closeButtonWrapper}>
      <IconButton onClick={onClose}>
        <JitIcon icon={Cross} size={25} />
      </IconButton>
    </div>
  </DialogTitle>
);
