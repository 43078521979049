import { Subscription, useStiggContext } from '@stigg/react-sdk';
import { useQuery } from 'react-query';

import { dateDiffInDays } from 'utils';

export interface CustomerTrialDetails {
  isOnTrial: boolean;
  daysLeft: number | null;
  isLoading: boolean;
  error: unknown;
  hasPaidSubscription: boolean;
}

const getDaysLeft = (endDate: Date | null): number | null => {
  if (!endDate) return null;

  const today = new Date();
  return dateDiffInDays(today, endDate);
};

export const useGetCustomerTrialDetails = (enabled = true): CustomerTrialDetails => {
  const { stigg, isInitialized } = useStiggContext();

  const fetchSubscriptions = async (): Promise<Subscription[]> => {
    if (!isInitialized || !stigg.isCustomerLoaded) {
      console.error('Stigg is not initialized or customer not loaded.');
      return [];
    }
    // According to Stigg Docs, the getActiveSubscriptions() can get filters param
    // Waiting for a response from Stigg as it is not working as expected
    return stigg.getActiveSubscriptions();
  };

  const { data: activeSubscriptions = [], isLoading, error } = useQuery(
    ['active-subscriptions'],
    fetchSubscriptions,
    { enabled: isInitialized && enabled },
  );

  const activeTrials = activeSubscriptions.filter(
    (subscription) => subscription.status === 'IN_TRIAL',
  );

  const paidSubscriptions = activeSubscriptions.filter(
    (subscription) => subscription.status === 'ACTIVE' && subscription.plan.pricingType !== 'FREE',
  );

  const isOnTrial = activeTrials.length > 0;
  const hasPaidSubscription = paidSubscriptions.length > 0;

  const trialEndDate = activeTrials[0]?.trialEndDate ?? null;
  const daysLeft = isOnTrial ? getDaysLeft(trialEndDate) : null;

  return {
    isOnTrial,
    daysLeft,
    isLoading,
    error,
    hasPaidSubscription,
  };
};
