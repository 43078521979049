import { useState, useCallback, useMemo } from 'react';

import { IGroup, IProject } from 'services/GitlabService/useGitlabService';

export interface SelectableItem {
  isSelected: boolean;
}

export type SelectableAsset = (IProject | IGroup) & SelectableItem;

interface UseAssetSelectionProps {
  alreadySelectedProjects?: IProject[];
  alreadySelectedSubgroups?: IGroup[];
  projectsResults?: IProject[];
  subgroupsResults?: IGroup[];
}

export const useAssetSelection = ({
  alreadySelectedProjects = [],
  alreadySelectedSubgroups = [],
  projectsResults = [],
  subgroupsResults = [],
}: UseAssetSelectionProps) => {
  const initialItems = useMemo(() => [
    ...alreadySelectedSubgroups.map((group) => ({ ...group,
      isSelected: true })),
    ...alreadySelectedProjects.map((project) => ({ ...project,
      isSelected: true })),
  ], [alreadySelectedProjects, alreadySelectedSubgroups]);

  const [selectedItems, setSelectedItems] = useState<SelectableAsset[]>(initialItems);

  const subgroups = useMemo(
    () => subgroupsResults.map((group) => ({
      ...group,
      isSelected: selectedItems.some((selected) => selected.id === group.id),
    })),
    [subgroupsResults, selectedItems],
  );

  const projects = useMemo(
    () => projectsResults.map((project) => ({
      ...project,
      isSelected: selectedItems.some((selected) => selected.id === project.id),
    })),
    [projectsResults, selectedItems],
  );

  const handleItemSelect = useCallback((itemId: string, checked: boolean, isGroup: boolean) => {
    const items: SelectableAsset[] = isGroup ? subgroups : projects;
    const itemToUpdate = items.find((item) => item.id === itemId);

    if (!itemToUpdate) return;

    if (checked) {
      setSelectedItems((prev) => [...prev, { ...itemToUpdate,
        isSelected: true }]);
    } else {
      setSelectedItems((prev) => prev.filter((item) => item.id !== itemId));
    }
  }, [subgroups, projects]);

  const handleSelectedItemUncheck = useCallback((itemId: string) => setSelectedItems((prev) => prev.filter((item) => item.id !== itemId)), []);

  const getSelectedAssets = useCallback(() => ({
    selectedProjects: selectedItems.filter((item) => !('fullPath' in item)) as IProject[],
    selectedGroups: selectedItems.filter((item) => 'fullPath' in item) as IGroup[],
  }), [selectedItems]);

  const handleGroupSelect = useCallback((id: string, checked: boolean) => handleItemSelect(id, checked, true), [handleItemSelect]);

  const handleProjectSelect = useCallback((id: string, checked: boolean) => handleItemSelect(id, checked, false), [handleItemSelect]);

  return {
    subgroups,
    projects,
    selectedItems,
    handleGroupSelect,
    handleProjectSelect,
    handleSelectedItemUncheck,
    getSelectedAssets,
  };
};
