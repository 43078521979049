import { FC, ReactElement } from 'react';

import { AddRemoveButton } from 'pages/PoliciesPage/components/PolicyBuilder/components/AddRemoveButton';
import { BaseConditionItem } from 'pages/PoliciesPage/components/PolicyBuilder/components/BaseConditionItem';
import { CallbackProps } from 'pages/PoliciesPage/components/PolicyBuilder/hooks/useOptionsQueries';
import styles from 'pages/PoliciesPage/components/PolicyBuilder/RuleBuilder.module.scss';
import { CallbackKey } from 'pages/PoliciesPage/types';

interface Props {
  condition: ReactElement[];
  index: number;
  totalConditions: number;
  activateCallback: (callback: CallbackKey) => void;
  isEditMode: boolean;
  getCallbackProps: (callback: CallbackKey) => CallbackProps;
  handleAddCondition: () => void;
  handleRemoveCondition: (index: number) => void;
  stackAddButton?: boolean;
}

export const FilterConditionItem: FC<Props> = ({
  index,
  condition,
  totalConditions,
  activateCallback,
  isEditMode,
  getCallbackProps,
  handleAddCondition,
  handleRemoveCondition,
  stackAddButton = false,
}) => {
  const isLastConditionItem = index === totalConditions;
  const addButtonComponent = isLastConditionItem && isEditMode && (
    <AddRemoveButton onClick={handleAddCondition} type='add' />
  );

  return (
    <div key={index} className={styles.filterConditionRow}>
      <div className={stackAddButton ? styles.conditionStacked : styles.condition}>
        <div className={styles.conditionContent}>
          {condition?.map((rule) => (
            <BaseConditionItem
              key={`${rule.key}_${index}`}
              activateCallback={activateCallback}
              getCallbackProps={getCallbackProps}
              rule={rule}
            />
          ))}

          {!stackAddButton && addButtonComponent}

          {isEditMode && <AddRemoveButton onClick={() => { handleRemoveCondition(index); }} type='remove' />}
        </div>

        {stackAddButton && addButtonComponent && (
          <div className={styles.stackedAddButton}>
            {addButtonComponent}
          </div>
        )}
      </div>
    </div>
  );
};
