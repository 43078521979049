import React, { useCallback, useMemo, useState } from 'react';

import { useQuickstartGuide } from '../../hooks/useQuickstartGuide';
import styles from '../ButtonMainComponent.module.scss';

import { ITenantStageProps, SlackIntegrationDialog, SlackIntegrationDialogStage } from 'components/JitDialogs/SlackIntegrationDialog/SlackIntegrationDialog';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { INTEGRATE_SLACK_CHECK_SLUG } from 'context/TenantContext/constant';
import { constants } from 'globalConstants';
import { useFetchIntegrations } from 'pages/IntegrationsPage/hooks/useFetchIntegrations';
import { getSlackRedirectUri, SlackRedirectParams } from 'pages/IntegrationsPage/utils';
import { CheckStatus } from 'pages/QuickStartGuide/types';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';

const baseUrl = window.location.origin;
const quickstartPath = `${baseUrl}/${constants.routes.QUICKSTART}`;
const slackIntegDialogType: ITenantStageProps = { type: SlackIntegrationDialogStage.TENANT };

export const IntegrateSlackButton: React.FC = () => {
  const [showSlackConfigureDialog, setShowSlackConfigureDialog] = useState(false);
  const { checksMap } = useQuickstartGuide();
  const { integrations } = useFetchIntegrations();

  const isSlackIntegrationComplete = useMemo(() => {
    const slackIntegrationCheck = checksMap[INTEGRATE_SLACK_CHECK_SLUG];
    return slackIntegrationCheck?.status === CheckStatus.COMPLETED;
  }, [checksMap]);

  const slackInstallRedirectUri = useMemo(() => {
    const slackIntegration = integrations.find(({ vendor }) => vendor === Vendor.SLACK);
    const slackRedirectParams: SlackRedirectParams = {
      httpRedirect: `${quickstartPath}`,
    };
    return getSlackRedirectUri(slackIntegration?.installation_path, slackRedirectParams);
  }, [integrations]);

  const handleClose = useCallback(() => setShowSlackConfigureDialog(false), []);
  const handleIntegrateSlackClick = useCallback(() => {
    window.open(slackInstallRedirectUri, '_self');
  }, [slackInstallRedirectUri]);

  const handleConfigureSlackClick = useCallback(() => {
    setShowSlackConfigureDialog(true);
  }, []);

  return (
    <>
      <SlackIntegrationDialog
        isOpen={showSlackConfigureDialog}
        onClose={handleClose}
        stage={slackIntegDialogType}
      />

      {isSlackIntegrationComplete ? (
        <JitText
          color={colors.iris}
          onClick={handleConfigureSlackClick}
          text='pages.quickStart.checks.configureSlack.configureButton'
        />
      ) : (
        <JitGradientButton onClick={handleIntegrateSlackClick}>
          <div className={styles.buttonContent}>
            <JitText bold fontFamily='Inter, serif' size='s' text='pages.quickStart.checks.configureSlack.integrateButton' />
          </div>
        </JitGradientButton>
      )}
    </>
  );
};
