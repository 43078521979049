import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IGithubStatusAlert } from 'types/enums/GithubStatus';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { FetchMembersParams } from 'types/interfaces/SCM/SCMMember';

const serviceName = 'github';

export const useGithubService = () => {
  const { client } = useClient();

  const fetchGithubStatus = useCallback(async () => {
    const url = `${serviceName}/github-status`;
    return client.get<IGithubStatusAlert>({
      url,
      allowedStatuses: [200],
    });
  }, [client]);

  const getMembers = useCallback(async (params: FetchMembersParams) => {
    const url = getApiUrls.githubService.getMembers();
    const response = await client.get<IPaginatedResponse<string>>({
      url,
      allowedStatuses: [200],
      requestConfig: { params },
    });

    if (response?.status === 200) {
      return response.data;
    }
    throw new Error('Error fetching members');
  }, [client]);

  return {
    fetchGithubStatus,
    getMembers,
  };
};
