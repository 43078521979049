import { CircularProgressProps } from '@mui/material/CircularProgress';
import { FC } from 'react';

import { StyledCircularProgress } from './CircularProgressBar.styles';

interface Props extends CircularProgressProps {
  value?: number;
  maxValue?: number;
  width?: number;
  className?: string;
}

export const CircularProgressBar: FC<Props> = ({ value = 0, maxValue = 0, className = '', ...props }) => {
  const finalValue = value > maxValue ? maxValue : value;
  return (
    <StyledCircularProgress
      className={className}
      data-testid='circular-progress-bar'
      value={Math.round((finalValue / maxValue) * 100)}
      {...props}
    />
  );
};
