import { FC } from 'react';

import { PremiumLock } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { TableGradient } from 'components/JitTable/components/TableGradient/TableGradient';
import { JitText } from 'components/JitText/JitText';
import { JitUpgradeButton } from 'components/JitUpgradeButton/JitUpgradeButton';
import styles from 'pages/SbomPage/components/SbomTable/PaywallGradiant/PaywallGradiant.module.scss';

export const PaywallGradiant: FC = () => (
  <TableGradient>
    <div className={styles.titleAndLockWrapper}>
      <JitIcon icon={PremiumLock} />

      <JitText bold text='pages.sbom.table.paywallGradiant.title' />
    </div>

    <div className={styles.descriptionWrapper}>
      <JitText text='pages.sbom.table.paywallGradiant.description' />
    </div>

    <div className={styles.buttonWrapper}>
      <JitUpgradeButton text='pages.sbom.table.paywallGradiant.upgrade' />

    </div>
  </TableGradient>
);
