import { t } from 'i18next';
import { FC, useMemo, useCallback, useState } from 'react';

import styles from './TriggerSection.module.scss';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitText } from 'components/JitText/JitText';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { IWorkflowStepOption } from 'types/interfaces/Workflows/IWorkflowStepOptions';

export const TriggerSection: FC = () => {
  const {
    workflow,
    setWorkflow,
    stepsOptions,
    setSelectedStepId,
    selectedStepId,
  } = useEditWorkflowContext();

  const initialTriggerType = useMemo(() => {
    if (!workflow?.trigger) return undefined;
    return stepsOptions?.triggerOptions.find((option) => option.type === workflow.trigger.type);
  }, [workflow, stepsOptions]);

  const [selectedTriggerType, setSelectedTriggerType] = useState<IWorkflowStepOption | undefined>(initialTriggerType);

  const triggerOptions = useMemo(() => stepsOptions?.triggerOptions || [], [stepsOptions]);

  const onSelectTriggerOptions = useCallback(
    (selected: IWorkflowStepOption) => {
      setSelectedTriggerType(selected);

      setWorkflow((prevWorkflow) => ({
        ...prevWorkflow,
        trigger: {
          ...(prevWorkflow.trigger || {}),
          type: selected.type,
          name: selected.label,
        },
      }));
      setSelectedStepId(selectedStepId);
    },
    [setWorkflow, setSelectedStepId, selectedStepId],
  );

  return (
    <div className={styles.triggerSection}>
      <JitText bold muted size='m' text='pages.workflows.editWorkflowPage.stepDetails.trigger.ctaQuestion' />

      <div data-testid='TriggerStepTypeSelector'>
        <JitAutoCompleteForm
          autoFocus={false}
          clearOnBlur={false}
          fitContent
          fontSize={14}
          getOptionKey={(option) => option.type}
          getOptionLabel={(option) => option.label}
          isSingleValue
          options={triggerOptions}
          placeHolder={t`pages.workflows.editWorkflowPage.stepDetails.trigger.addTrigger`}
          selectedValue={selectedTriggerType}
          setSelectedValue={onSelectTriggerOptions}
          showArrow={false}
          variant='light'
        />
      </div>
    </div>
  );
};
