import { FC } from 'react';

import { RuleSettings, BaseRuleTemplate } from '../../types';

import { useRuleBuilder } from './hooks/useRuleBuilder';
import { useRuleCallbacks } from './hooks/useRuleCallbacks';
import styles from './RuleBuilder.module.scss';

import { JitChip } from 'components/JitChip/JitChip';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { AddRemoveButton } from 'pages/PoliciesPage/components/PolicyBuilder/components/AddRemoveButton';
import { BaseConditionItem } from 'pages/PoliciesPage/components/PolicyBuilder/components/BaseConditionItem';
import { FilterConditionItem } from 'pages/PoliciesPage/components/PolicyBuilder/components/FilterConditionItem';
import { useManageSettings } from 'pages/PoliciesPage/components/PolicyBuilder/hooks/useManageSettings';

interface Props {
  settings: RuleSettings;
  ruleTemplate: BaseRuleTemplate;
  isEditMode: boolean;
  onSettingsChange: (updatedSettings: RuleSettings) => void;
  title?: string;
  stackAddButton?: boolean;
}

export const RuleBuilder: FC<Props> = ({
  settings,
  ruleTemplate,
  isEditMode,
  onSettingsChange,
  title,
  stackAddButton = false,
}) => {
  const {
    handleAddCondition,
    handleRemoveCondition,
    onFieldChange,
  } = useManageSettings({
    settings,
    ruleTemplateInputs: ruleTemplate.inputs,
    onSettingsChange,
  });

  const {
    baseConditionSentence,
    filterConditionSentence,
  } = useRuleBuilder({
    template: ruleTemplate,
    settings,
    isEditMode,
    onFieldChange,
  });

  const {
    getCallbackProps,
    activateCallback,
  } = useRuleCallbacks();

  const hasConditions = filterConditionSentence?.length > 0;

  return (
    <div className={styles.ruleInputs} data-testid='ruleInputs'>
      <div className={styles.conditionsWrapper}>
        <div className={styles.condition}>
          {title && <JitText muted paddingRight={3} size='xs' text={title} />}

          {baseConditionSentence.map((rule) => (
            <BaseConditionItem
              key={rule.key}
              activateCallback={activateCallback}
              getCallbackProps={getCallbackProps}
              rule={rule}
            />
          ))}
        </div>

        {hasConditions && (
          <div className={styles.filterConditions} data-testid='filterConditionWrapper'>
            {filterConditionSentence.map((condition, index) => (
              <>
                <FilterConditionItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  activateCallback={activateCallback}
                  condition={condition}
                  getCallbackProps={getCallbackProps}
                  handleAddCondition={handleAddCondition}
                  handleRemoveCondition={handleRemoveCondition}
                  index={index}
                  isEditMode={isEditMode}
                  stackAddButton={stackAddButton}
                  totalConditions={filterConditionSentence.length - 1}
                />

                {!(index === filterConditionSentence.length - 1) && <JitChip label={i18n.t('pages.policies.rule.divider.and')} />}
              </>
            ))}
          </div>
        )}

        {isEditMode && !filterConditionSentence?.length && <AddRemoveButton onClick={handleAddCondition} type='add' />}
      </div>
    </div>
  );
};
