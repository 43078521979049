import { CSSProperties, FC, useMemo } from 'react';

import styles from './ThreatPrevention.module.scss';

import { JitText } from 'components/JitText/JitText';
import { LineProgress } from 'components/LineProgress/LineProgress';
import colors from 'themes/colors.module.scss';
import { TextSize } from 'utils/constants/textSizeMap';

const sizesMap: { [key: string]: {
  resolvedFindings: TextSize,
  contentStyle: CSSProperties,
} } = {
  s: {
    resolvedFindings: 'l',
    contentStyle: {
      justifyContent: 'center',
      gap: '10px',
    },
  },
  l: {
    resolvedFindings: 'huge',
    contentStyle: {
      justifyContent: 'space-between',
    },
  },
};

interface Props {
  preProdResolvedFindings: number;
  totalResolvedFindings: number;
  size?: 's' | 'l';
  width?: number;
}

export const ThreatPreventionContent: FC<Props> = ({ preProdResolvedFindings, totalResolvedFindings, width, size = 'l' }) => {
  const percentage = (preProdResolvedFindings / totalResolvedFindings) * 100;
  const color = percentage > 60 ? colors.performanceGreen : colors.alert;
  const content = useMemo(() => (
    <div className={styles.wrapper} style={sizesMap[size].contentStyle}>
      <div className={styles.contentWrapper} data-testid='threatPreventionData'>
        <JitText bold data-testid='threat-prevention-pre-prod-resolved-findings' size={sizesMap[size].resolvedFindings} text={preProdResolvedFindings} />

        <JitText bold color={colors.gray} data-testid='threat-prevention-slash' size='l' text='/' />

        <JitText bold color={colors.gray} data-testid='threat-prevention-total-resolved-findings' size='l' text={totalResolvedFindings} />

      </div>

      <LineProgress color={color} percentage={percentage} width={width} />
    </div>
  ), [color, percentage, preProdResolvedFindings, size, totalResolvedFindings, width]);

  return content;
};
