import { useEffect, useState } from 'react';

import styles from './useReposErrorToast.module.scss';

import { JitText } from 'components/JitText/JitText';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { AssetStatus, AssetType, Dialogs, ToastType } from 'types/enums';
import { IAsset } from 'types/interfaces';

export interface ReposErrorProps {
  assets: IAsset[];
}

export const useReposErrorToast = ({ assets }: ReposErrorProps) => {
  const { showDialog } = useDialogsContext();
  const { showToast } = useToastsContext();
  const [didShowToast, setDidShowToast] = useState(false);

  return useEffect(() => {
    if (didShowToast) {
      return;
    }
    const assetsWithNoDefaultBranch = assets.filter(
      (asset) => asset.asset_type === AssetType.REPO && asset.status === AssetStatus.FAILED && asset.status_details?.toLowerCase().includes('branch'),
    );
    if (assetsWithNoDefaultBranch.length === 0) {
      return;
    }
    const repos = assetsWithNoDefaultBranch.slice(0, 2).map((item) => item.asset_name).join(', ');
    showToast({
      toastId: 'missing-jit-branch',
      type: ToastType.SystemError,
      shouldBeRenderedTop: true,
      overrideProps: {
        secondsToClose: 300,
        title: 'toasts.missingJitBranch.title',
        content: (
          <JitText
            className={styles.textWrapper}
            components={
              {
                ManageResourcesLink: <JitText
                  className={styles.subtitleLink}
                  noWrap
                  onClick={() => showDialog({ type: Dialogs.AssetsManagement })}
                  overflowHiddenEllipsis
                  text='toasts.missingJitBranch.subtitleLink'
                  withUnderline
                />,
                ReposList: <JitText
                  className={styles.reposList}
                  noWrap
                  overflowHiddenEllipsis
                  text={repos}
                />,
              }
            }
            noWrap
            overflowHiddenEllipsis
            text='toasts.missingJitBranch.subtitle'
          />
        ),
      },
    });
    setDidShowToast(true);
  }, [assets, didShowToast, showDialog, showToast]);
};
