import { t } from 'i18next';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './MissingScmIntegration.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';

interface Props {
  subtitleAction: string;
  maxWidth?: string;
  onClick?: () => void;
}

export const MissingScmIntegration: FC<Props> = ({ subtitleAction, maxWidth, onClick }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (onClick) onClick();
    const plan = sessionStorage.getItem(constants.GOAL_PLAN_KEY) || constants.PLAN_SLUG.MVS;
    navigate(`/${constants.routes.PLANS}/${plan}`);
  }, [onClick, navigate]);

  return (
    <div className={styles.wrapper} data-testid='missing-scm-integration' style={{ maxWidth }}>
      <div className={styles.textWrapper}>
        <JitText align='center' bold data-testid='missing-scm-integration-title' size='l' text='table.missingScmIntegration.title' />

        <JitText align='center' data-testid='missing-scm-integration-subtitle' params={{ action: t(subtitleAction) as string }} size='m' text='table.missingScmIntegration.subtitle' />
      </div>

      <JitActionButton actionButton={{
        label: 'table.missingScmIntegration.buttonText',
        handleClick,
      }}
      />

    </div>
  );
};

