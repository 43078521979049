import { FC, useMemo } from 'react';

import styles from './IconTextBox.module.scss';

import { JitText } from 'components/JitText/JitText';
import { TextSize } from 'utils/constants/textSizeMap';

interface IconTextBoxProps {
  icon?: JSX.Element;
  text: string | number;
  color: string;
  withBorder?: boolean;
  borderOpacity?: number;
  testId?: string;
  size?: TextSize;
  bold?: boolean;
}

export const IconTextBox: FC<IconTextBoxProps> = ({
  icon, text, color, withBorder = true, borderOpacity = 1, testId = 'iconTextBox', size = 's', bold = true,
}) => {
  const borderColor = useMemo(() => (
    withBorder ? `${color}${Math.round(borderOpacity * 255).toString(16).padStart(2, '0')}` : 'transparent'
  ), [withBorder, color, borderOpacity]);

  // This is a workaround to align numbers with min character width of 2
  const numberAlignmentStyle = useMemo(() => (
    typeof text === 'number' ? styles.number : ''
  ), [text]);

  return (
    <div
      className={styles.wrapper}
      data-testid={testId}
      style={{
        borderColor,
        width: withBorder ? 55 : 'fit-content',
        padding: withBorder ? 4 : 0,
      }}
    >
      {icon}

      <JitText bold={bold} className={numberAlignmentStyle} color={color} size={size} text={text} />
    </div>
  );
};
