import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { AssetSection } from './components/AssetSection/AssetSection';
import { CoverageSelectionHeader } from './components/CoverageSelectionHeader/CoverageSelectionHeader';
import { CoverageSelectionSearch } from './components/CoverageSelectionSearch/CoverageSelectionSearch';
import styles from './CoverageSelectionDialog.module.scss';
import { useAssetSelection } from './hooks/useAssetSelection';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitText } from 'components/JitText/JitText';
import { IGroup, IProject } from 'services/GitlabService/useGitlabService';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant } from 'types/interfaces';

interface Props {
  onClose: () => void;
  onConfirm: (selectedProjects: IProject[], selectedGroups: IGroup[]) => void;
  handleSearch: (value: string) => void;
  alreadySelectedProjects?: IProject[];
  alreadySelectedSubgroups?: IGroup[];
  projectsResults?: IProject[];
  subgroupsResults?: IGroup[];
  isLoading?: boolean;
}

const dialogStyle = {
  backgroundColor: colors.cards,
  border: `1px solid ${colors.darkGray}`,
  borderRadius: 20,
  minWidth: 844,
  minHeight: 600,
  maxHeight: 600,
  backgroundImage: 'none',
};

export const CoverageSelectionDialog = ({
  onClose,
  onConfirm,
  handleSearch,
  alreadySelectedProjects = [],
  alreadySelectedSubgroups = [],
  projectsResults = [],
  subgroupsResults = [],
  isLoading,
}: Props) => {
  const {
    subgroups,
    projects,
    selectedItems,
    handleGroupSelect,
    handleProjectSelect,
    handleSelectedItemUncheck,
    getSelectedAssets,
  } = useAssetSelection({
    alreadySelectedProjects,
    alreadySelectedSubgroups,
    projectsResults,
    subgroupsResults,
  });

  const handleConfirm = () => {
    const { selectedProjects, selectedGroups } = getSelectedAssets();
    onConfirm(selectedProjects, selectedGroups);
  };

  return (
    <Dialog
      data-testid='CoverageSelectionDialog'
      onClose={onClose}
      open
      PaperProps={{ style: dialogStyle }}
    >
      <CoverageSelectionHeader onClose={onClose} />

      <DialogContent className={styles.content}>
        <CoverageSelectionSearch onChange={handleSearch} />

        {(projectsResults?.length === 20 || subgroupsResults?.length === 20) && (
          <div className={styles.searchHint}>
            <JitText
              muted
              size='s'
              text='GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.refineSearch'
            />
          </div>
        )}

        <div className={styles.stepWrapper}>
          <AssetSection
            dataTestId='selected-section'
            items={selectedItems}
            onItemCheck={handleSelectedItemUncheck}
            title='GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.selectedSection'
          />

          <AssetSection
            dataTestId='subgroups-section'
            isLoading={isLoading}
            items={subgroups}
            onItemCheck={handleGroupSelect}
            title='GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.subgroupsSection'
          />

          <AssetSection
            dataTestId='projects-section'
            isLoading={isLoading}
            items={projects}
            onItemCheck={handleProjectSelect}
            title='GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.projectsSection'
          />
        </div>
      </DialogContent>

      <DialogActions className={styles.actionButtonWrapper}>
        <JitActionButton
          actionButton={{
            variant: ActionButtonVariant.OUTLINED,
            label: 'GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.cancelButton',
            handleClick: onClose,
          }}
        />

        <JitActionButton
          actionButton={{
            variant: ActionButtonVariant.PRIMARY,
            label: 'GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.selectButton',
            handleClick: handleConfirm,
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

