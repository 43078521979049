import { FC } from 'react';

import styles from '../ConditionSection.module.scss';

import { EditPen, Trash } from 'assets';
import { IconButton } from 'components/IconButton/IconButton';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { RuleSettings } from 'pages/PoliciesPage/types';
import colors from 'themes/colors.module.scss';

interface ConditionActionsProps {
  isInEditMode: boolean;
  updatedSettings: RuleSettings;
  onSaveChanges: (settingsToSave: RuleSettings) => void;
  onDiscardChanges: () => void;
  onDeleteSettings: () => void;
  onSetEditMode: (value: boolean) => void;
  isDirty: boolean;
  isValid: boolean;
}

/**
 * Renders either the Save/Cancel buttons (if in edit mode)
 * or the Edit/Delete buttons if not in edit mode.
 */
export const ConditionActions: FC<ConditionActionsProps> = ({
  isInEditMode,
  updatedSettings,
  onSaveChanges,
  onDiscardChanges,
  onDeleteSettings,
  onSetEditMode,
  isDirty,
  isValid,
}) => {
  if (isInEditMode) {
    return (
      <div className={styles.formButtons}>
        <div className={styles.saveButtonWrapper}>
          <JitGradientButton
            data-testid='saveRuleButton'
            disabled={!isDirty || !isValid}
            height={38}
            onClick={() => onSaveChanges(updatedSettings)}
            width={65}
          >
            <div className={styles.saveButton}>
              <JitText
                bold
                fontFamily='Inter, serif'
                size='m'
                text='pages.policies.rule.buttons.save.label'
              />
            </div>
          </JitGradientButton>
        </div>

        <JitText
          color={colors.iris}
          data-testid='cancelRuleEditButton'
          onClick={onDiscardChanges}
          size='m'
          text='pages.policies.rule.buttons.cancel.label'
        />
      </div>
    );
  }

  // Not in edit mode -> show Edit & Delete icons
  return (
    <div className={styles.buttonsWrapper}>
      <div className={styles.controlButtons}>
        <IconButton
          color={colors.iris}
          icon={EditPen}
          onClick={() => onSetEditMode(true)}
          size={14}
          testId='editRuleButton'
          tooltipText='pages.policies.rule.buttons.edit.tooltip'
        />

        <IconButton
          icon={Trash}
          onClick={onDeleteSettings}
          size={14}
          testId='deleteRuleButton'
          tooltipText='pages.policies.rule.buttons.delete.tooltip'
          withBackSquare
        />
      </div>
    </div>
  );
};
