import { FC } from 'react';

import { ISvg } from 'types/interfaces';

export enum TabItemTypes {
  item = 'item',
  group = 'group',
}

export interface IconProps {
  color: string;
  size: number;
}

interface IBaseTabItem {
  name: string;
  icon?: FC<ISvg> | FC<IconProps>;
  shouldHide?: boolean;
  isDisabled?: boolean;
  isNew?: boolean;
}

export interface ITabItem extends IBaseTabItem {
  onClick?: () => void;
  route?: string; // Relevant only for page tab items
  isDefault?: boolean; // Relevant only for page tab items - Indicated the default page in our tab
  type: TabItemTypes.item;
}

export interface ITabGroup extends IBaseTabItem {
  items: ITabItem[];
  type: TabItemTypes.group;
}

export type Tab = ITabItem | ITabGroup;
