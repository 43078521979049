import { Dispatch, SetStateAction, useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { ISpecificTeamState } from '../TeamsProvider';

import { Queries } from 'types/enums/Queries';
import { TagNames } from 'types/enums/Tags';
import { IAsset, IWebsocketNotification } from 'types/interfaces';

interface Props {
  setSpecificTeam: Dispatch<SetStateAction<ISpecificTeamState>>,
}

export const useHandleTeamAssetsWebSocketNotification = ({ setSpecificTeam }: Props) => {
  const queryClient = useQueryClient();

  const modifySpecificTeamAssets = (
    specificTeam: ISpecificTeamState,
    modifiedAssets: IAsset[],
  ) => {
    if (!specificTeam.team) {
      return specificTeam;
    }

    if (!specificTeam.resources.data || !modifiedAssets) {
      return specificTeam;
    }

    const teamCurrentAssetsById = specificTeam.resources.data.reduce((acc, asset) => {
      acc[asset.asset_id] = asset;
      return acc;
    }, {} as Record<string, IAsset>);

    modifiedAssets.forEach((modifiedAsset) => {
      if (!teamCurrentAssetsById[modifiedAsset.asset_id]) {
        teamCurrentAssetsById[modifiedAsset.asset_id] = modifiedAsset;
      }
      if (teamCurrentAssetsById[modifiedAsset.asset_id].score !== modifiedAsset.score) {
        // invalidate relevant queries, to make sure the asset's factors table is updated upon score change
        queryClient.invalidateQueries({ queryKey: [Queries.ResourceFactors, modifiedAsset.asset_id] });
        queryClient.invalidateQueries({ queryKey: [Queries.PlanItemsFindingsCount, modifiedAsset.asset_name, specificTeam.team?.name] });
        queryClient.invalidateQueries({
          predicate: (query) => {
            // invalidate the findings queries for the asset if it's relevant to the current team

            const { queryKey } = query;
            if (!Array.isArray(queryKey) || queryKey.length < 2) return false;

            const [queryName, filters] = queryKey as [string, Record<string, string>];
            // Check if filters is an object with the expected properties
            const isValidFilters = (obj: unknown): obj is { asset_name?: string; team?: string } => typeof obj === 'object' && obj !== null;

            const isQueryFilters = isValidFilters(filters) && (
              filters.asset_name === modifiedAsset.asset_name
              || filters.team === specificTeam.team?.name
            );

            return (queryName === Queries.Findings || queryName === Queries.FindingsCount) && !!isQueryFilters;
          },
        });
      }
      if (!modifiedAsset.tags.some((tag) => tag.name === TagNames.Teams && tag.value === specificTeam.team?.name)) {
        // delete the asset from the team's resources if it no longer belongs to the team
        delete teamCurrentAssetsById[modifiedAsset.asset_id];
      } else {
        teamCurrentAssetsById[modifiedAsset.asset_id] = {
          ...teamCurrentAssetsById[modifiedAsset.asset_id],
          ...modifiedAsset,
        };
      }
    });

    return {
      ...specificTeam,
      resources: {
        ...specificTeam.resources,
        data: Object.values(teamCurrentAssetsById),
      },
    };
  };

  const handleTeamAssetsWebSocketNotification = useCallback((notification: IWebsocketNotification<IAsset>) => {
    const { message: { updated } } = notification;

    setSpecificTeam((currentSpecificTeam) => modifySpecificTeamAssets(
      currentSpecificTeam,
      updated || [],
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSpecificTeam]);
  return { handleTeamAssetsWebSocketNotification };
};
