import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC } from 'react';

import { AssetsManagementButtons, Title, TopBar, TabPanel } from './components';
import { TABS, TABS_ARRAY } from './constants';
import { useAssetsDialogManagement } from './hooks';

import { useTenantContext } from 'context/TenantContext/TenantContext';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

const styles = {
  paperStyle: {
    backgroundColor: colors.cards,
    border: `1px solid ${colors.darkGray}`,
    borderRadius: 20,
    minWidth: 844,
    minHeight: 600,
    maxHeight: 600,
    backgroundImage: 'none',
    paddingLeft: 10,
    paddingRight: 10,
  },
  content: {
    padding: 0,
  },
};

interface Props {
  defaultTab?: TABS;
  onClose?: () => void
  tagAllAsCheckedForVendors?: string[]

  // An object to help inner components act based on the extra attributes
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  extraDetails?: {
    [key: string]: any
  }
}

export const AssetsManagementDialog: FC<Props> = ({
  defaultTab,
  onClose,
  tagAllAsCheckedForVendors,
  extraDetails,
}) => {
  const { scmInstallations, isGithubIntegrated, isGitlabIntegrated } = useTenantContext();
  let shouldDisableTab = (tab: TABS) => !isGithubIntegrated && tab === TABS.GITHUB;
  let startingTab = defaultTab || TABS.GITHUB;
  let tabsArray = [TABS.GITHUB].concat(TABS_ARRAY);
  // The tab param will not be needed once we remove the special handling for Github.
  if (isGitlabIntegrated) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldDisableTab = (tab: TABS) => false;
    startingTab = defaultTab || scmInstallations[0]?.vendor as TABS;
    const scmTabsArray = scmInstallations.map((installation) => installation.vendor as TABS);
    tabsArray = scmTabsArray.concat(TABS_ARRAY);
  }

  const {
    displayedAssets,
    handleClose,
    handleSave,
    handleSearchAsset,
    handleTabChange,
    handleToggleSelectAllAssets,
    isAllAssetsSelectedTabCovered,
    ownerDisplayNames,
    selectedTab,
    checkAsset,
    checkMultipleAssetsByOwner,
    tabDetails,
  } = useAssetsDialogManagement(startingTab, onClose, tagAllAsCheckedForVendors);

  return (
    <Dialog
      data-testid='AssetsManagementDialog'
      onClose={handleClose}
      open
      PaperProps={{ style: styles.paperStyle }}
      scroll='paper'
    >
      <Title
        onClose={handleClose}
        titleLinkDetails={tabDetails.titleLinkDetails}
      />

      <DialogContent style={styles.content}>

        <TopBar
          handleSearchAsset={handleSearchAsset}
          handleToggleSelectAllAssets={handleToggleSelectAllAssets}
          isAllAssetsSelectedTabCovered={isAllAssetsSelectedTabCovered}
          wholeSelectionCheckboxText={tabDetails.wholeSelectionCheckboxText}
        />

        <Box>
          <Tabs
            onChange={handleTabChange}
            style={{ borderBottom: `1px solid ${colors.darkGray}` }}
            TabIndicatorProps={{
              style: {
                backgroundColor: colors.blue02,
              },
            }}
            value={selectedTab}
          >

            {tabsArray.map((tab) => (
              <Tab
                key={tab}
                disabled={shouldDisableTab(tab)}
                label={i18n.t(`dialogs.assetsManagement.tabTitles.${tab}`) as string}
                style={{
                  paddingLeft: 45,
                  paddingRight: 45,
                  paddingBottom: 5,
                  color: shouldDisableTab(tab) ? colors.gray : colors.blue02,
                  textTransform: 'none',
                }}
                value={tab}
              />
            ))}
          </Tabs>
        </Box>

        {tabsArray.map((tab) => (
          <TabPanel key={tab} index={tab} value={selectedTab}>
            <tabDetails.SelectionListComponent
              assets={displayedAssets}
              checkAsset={checkAsset}
              checkMultipleAssetsByOwner={checkMultipleAssetsByOwner} // In use for the AWS tab
              handleClose={handleClose}
              ownerDisplayNames={ownerDisplayNames} // In use for the Github tab
              vendor={tab}
            />
          </TabPanel>
        ))}
      </DialogContent>

      <AssetsManagementButtons
        CustomLowerBarLeftComponent={tabDetails.CustomLowerBarLeftComponent}
        extraDetails={extraDetails}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    </Dialog>
  );
};
