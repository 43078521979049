import { useCallback, useEffect, useMemo, useState } from 'react';

import { ChooseGroup } from '../StepComponents/ChooseGitlabGroup/ChooseGroup';

import {
  IGitlabIntegrationWizardStep,
  IGitlabIntegrationWizardStepStatus,
} from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { useTenantContext } from 'context/TenantContext';
import { IGroup } from 'services/GitlabService/useGitlabService';
import { GitlabAccessLevel } from 'types/interfaces';

interface Props {
  incrementStepIndex: () => void;
  selectedGroup?: IGroup;
  setSelectedGroup: (group: IGroup) => void;
  selectedRole: GitlabAccessLevel;
  handleClose: () => void;
}

export const useChooseGroupStep = ({
  incrementStepIndex,
  selectedGroup,
  setSelectedGroup,
  selectedRole,
  handleClose,
}: Props): IGitlabIntegrationWizardStep => {
  const [isStepCompleted, setIsStepCompleted] = useState<boolean>(false);
  const [selectedSubgroup, setSelectedSubgroup] = useState<IGroup>();
  const { gitlabInstallation } = useTenantContext();

  useEffect(() => {
    if (gitlabInstallation && !selectedGroup) {
      setSelectedGroup({
        id: gitlabInstallation.installation_id,
        name: gitlabInstallation.owner,
        path: gitlabInstallation.owner,
        fullPath: gitlabInstallation.owner,
      });

      if (gitlabInstallation.centralized_repo_asset && !selectedSubgroup) {
        setSelectedSubgroup({
          id: gitlabInstallation.centralized_repo_asset.asset_id,
          name: gitlabInstallation.centralized_repo_asset.asset_name,
          path: gitlabInstallation.centralized_repo_asset.asset_name,
          fullPath: gitlabInstallation.centralized_repo_asset.asset_name,
        });

        setIsStepCompleted(true);
      }
    }
  }, [gitlabInstallation, selectedGroup, setSelectedGroup, selectedSubgroup, setSelectedSubgroup]);

  const status = useMemo(() => {
    if (isStepCompleted) return IGitlabIntegrationWizardStepStatus.COMPLETE;

    return IGitlabIntegrationWizardStepStatus.ACTIVE;
  }, [isStepCompleted]);

  const onContinueButtonClick = useCallback(async (callbackAction: () => Promise<boolean>) => {
    if (isStepCompleted) {
      incrementStepIndex();
      return;
    }

    const wasDone = await callbackAction();
    if (wasDone) {
      setIsStepCompleted(true);
      incrementStepIndex();
    }
  }, [incrementStepIndex, isStepCompleted]);

  const chooseGroupStep = useMemo(
    () => ({
      title: 'GitlabIntegrationWizard.chooseGitlabGroup.tabText',
      component: () => (
        <ChooseGroup
          handleClose={handleClose}
          onContinueButtonClick={onContinueButtonClick}
          selectedGroup={selectedGroup}
          selectedRole={selectedRole}
          selectedSubgroup={selectedSubgroup}
          setSelectedGroup={setSelectedGroup}
          setSelectedSubgroup={setSelectedSubgroup}
        />
      ),
      status,
    }),
    [
      selectedRole,
      status,
      onContinueButtonClick,
      selectedGroup,
      setSelectedGroup,
      selectedSubgroup,
      setSelectedSubgroup,
      handleClose,
    ],
  );

  return chooseGroupStep;
};
