import { uniqBy } from 'lodash';
import { FC } from 'react';

import styles from './ResourcesCell.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useTenantContext } from 'context/TenantContext';
import colors from 'themes/colors.module.scss';
import { ITableCell } from 'types/interfaces';
import { IComponentGroupAssets } from 'types/interfaces/Sbom/SbomComponentGroup';
import { stopPropagation } from 'utils';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

export const ResourcesCell: FC<ITableCell> = ({ cell: { value } }) => {
  const resources = value.assets as IComponentGroupAssets;
  const { currentScmVendor } = useTenantContext();
  const { getIconByVendor } = useDetailsByVendor();
  const uniqueResources = uniqBy(Object.values(resources), (resource) => `${resource.orgAssetName}/${resource.repoAssetName}`);
  const moreThanOneResource = uniqueResources.length > 1;
  return (
    <div className={styles.resourcesLinesWrapper}>
      {moreThanOneResource
        && (
        <JitText
          bold
          color={colors.lightGray}
          params={{ amount: uniqueResources.length }}
          style={{ marginTop: value.expanded ? 17 : 0 }}
          text='pages.sbom.table.resourcesAmount'
        />
        )}

      {(value.expanded || !moreThanOneResource) && uniqueResources.map(({ orgAssetName, repoAssetName }) => {
        const link = config.getRepoLink(orgAssetName, repoAssetName, currentScmVendor!);

        return (
          <div key={`${orgAssetName}/${repoAssetName}`} className={styles.resourceLine}>
            <JitIcon centered color={colors.iris} icon={getIconByVendor(currentScmVendor)} size={12} />

            <JitLink
              href={link}
              onClick={stopPropagation}
              rel='noreferrer'
              style={{ textDecoration: 'none' }}
              target='_blank'
            >
              <JitText color={colors.iris} text={`${orgAssetName}/${repoAssetName}`} />
            </JitLink>
          </div>
        );
      })}
    </div>
  );
};

