import Avatar from '@mui/material/Avatar';
import { FC } from 'react';

import styles from './WorkflowInfoPopper.module.scss';

import { JitText } from 'components/JitText/JitText';
import { formatDate } from 'utils';

interface WorkflowInfoPopperProps {
  createdAt: string;
  createdBy: string;
}

export const WorkflowInfoPopper: FC<WorkflowInfoPopperProps> = ({ createdAt, createdBy }) => (
  <div className={styles.wrapper} data-testid='workflow-info-popper'>
    <JitText muted params={{ createdBy }} size='xs' text='pages.workflows.editWorkflowPage.header.workflowDetails.header' />

    <div className={styles.avatarWrapper}>
      <Avatar
        alt={createdBy}
        src={`https://ui-avatars.com/api/?name=${createdBy}`}
        sx={{
          width: 36,
          height: 36,
        }}
      />

      <div className={styles.detailsSection}>
        <JitText
          params={{
            createdAt: createdAt ? formatDate(new Date(createdAt), false) : '-',
          }}
          size='s'
          text='pages.workflows.editWorkflowPage.header.workflowDetails.createdAt'
        />

        <JitText
          muted
          params={{ createdBy: createdBy || '-' }}
          size='xs'
          text='pages.workflows.editWorkflowPage.header.workflowDetails.createdBy'
        />
      </div>
    </div>
  </div>
);
