import { t } from 'i18next';

import styles from './CoverageSelectionSearch.module.scss';

import { DefaultTextField } from 'components/JitAutoCompleteForm/components/components/DefaultTextField';
import { JitIcon } from 'components/JitIcon/JitIcon';
import colors from 'themes/colors.module.scss';
import { iconsMap } from 'utils';

interface Props {
  onChange: (value: string) => void;
}

export const CoverageSelectionSearch = ({ onChange }: Props) => (
  <div className={styles.searchContainer}>
    <DefaultTextField
      autoFocus
      fullWidth
      InputProps={{
        inputProps: {
          className: styles.searchInput,
        },
        className: styles.searchField,
        startAdornment: (
          <JitIcon
            className={styles.searchIcon}
            color={colors.gray}
            icon={iconsMap.search.icon}
          />
        ),
      }}
      onChange={(e) => onChange(e.target.value)}
      placeholder={t('GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.searchBoxPlaceholder')}
    />
  </div>
);

