import { FC, ReactElement, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { SelectPageDropdown } from './components/SelectPageDropdown/SelectPageDropdown';
import styles from './InsightsPage.module.scss';
import { getInsightOptions } from './utils/getInsightOptions';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';

interface Props {
  pageKey: string
  page: ReactElement
}

const insightsOptions = getInsightOptions();

export const InsightsPage: FC<Props> = ({ pageKey, page }) => {
  const navigate = useNavigate();

  const selectedInsight = useMemo(
    () => insightsOptions.find((insight) => insight.key === pageKey),
    [pageKey],
  );

  useEffect(() => {
    if (!selectedInsight) {
      navigate('/');
    }
  }, [selectedInsight, navigate, pageKey]);

  if (!selectedInsight) return null;

  return (
    <PageLayout>
      <div className={styles.wrapper}>
        {!!insightsOptions.length && (
        <SelectPageDropdown insightsOptions={insightsOptions} selectedInsightPage={pageKey} />
        )}

        {page}
      </div>
    </PageLayout>
  );
};
