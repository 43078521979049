import { FC } from 'react';

import styles from './PlanItemStatusBadge.module.scss';

import { DotQueued, ErrorDot, FailedDot, GreenDotCheck } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { PlanItemAggregatedStatus } from 'types/interfaces';
import { TextSize } from 'utils/constants/textSizeMap';

export type PlanItemStatusBadgeSize = 's' | 'm';

interface Props {
  status: PlanItemAggregatedStatus;
  size?: PlanItemStatusBadgeSize;
}

interface ISizeToStyleMap {
  [key: string]: {
    iconSize: number;
    textSize: TextSize;
    borderRadius: number;
    padding: string;
  };
}

const sizeToStyleMap: ISizeToStyleMap = {
  s: {
    iconSize: 12,
    textSize: 's',
    borderRadius: 12,
    padding: '2px 6px',
  },
  m: {
    iconSize: 20,
    textSize: 's',
    borderRadius: 6,
    padding: '3px 7px 3px 4px',
  },
};

const statusToStyleMap = {
  [PlanItemAggregatedStatus.SUCCESS]: {
    icon: GreenDotCheck,
    backgroundColor: colors.pipelineSuccessStatusBG,
    textColor: colors.successGreen02,
    text: 'pages.plan.planItem.passed',
  },
  [PlanItemAggregatedStatus.FAILURE]: {
    icon: FailedDot,
    backgroundColor: colors.pipelineFailedStatusBG,
    textColor: colors.pipelineFailedStatusText,
    text: 'pages.plan.planItem.failed',
  },
  [PlanItemAggregatedStatus.ERROR]: {
    icon: ErrorDot,
    backgroundColor: colors.pipelineFailedStatusBG,
    textColor: colors.pipelineFailedStatusText,
    text: 'pages.plan.planItem.error',
  },
  [PlanItemAggregatedStatus.PENDING]: {
    icon: DotQueued,
    backgroundColor: colors.pipelineQueuedStatusBGNew,
    textColor: colors.pipelineQueuedStatusText,
    text: 'pages.plan.planItem.pending',
  },
};

export const PlanItemStatusBadge: FC<Props> = ({ status, size = 's' }) => {
  const { icon, backgroundColor, textColor, text } = statusToStyleMap[status];
  const { iconSize, textSize, borderRadius, padding } = sizeToStyleMap[size];

  return (
    <div data-testid='status-badge-container'>
      <div
        className={styles.statusBox}
        data-testid='status-box'
        style={{
          backgroundColor,
          borderRadius,
          padding,
        }}
      >
        <JitIcon
          centered
          data-testid='icon'
          icon={icon}
          size={iconSize}
        />

        <JitText color={textColor} size={textSize} text={text} />

      </div>
    </div>
  );
};
