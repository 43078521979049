export const policyRuleOptionsCallbackMap = {
  fetchFindingPriorityFactorsOptions: 'fetchFindingPriorityFactorsOptions',
  fetchAssetPriorityFactorsOptions: 'fetchAssetPriorityFactorsOptions',
  fetchFindingTypesOptions: 'fetchFindingTypesOptions',
  fetchAssetTypesOptions: 'fetchAssetTypesOptions',
  fetchFindingTeamsOptions: 'fetchFindingTeamsOptions',
  fetchAssetNamesOptions: 'fetchFindingTypesOptions',
  fetchSCMMembers: 'fetchSCMMembers',
};

export const allowIgnoreFindingsPolicySlug = 'allow_ignore_platform_findings';

export const MEMBERS_OPTIONS_FETCH_LIMIT = 100;
