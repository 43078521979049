export enum Queries {
  ScoreTrending = 'scoreTrending',
  Teams = 'teams',
  TeamById = 'teamById',
  ParagonEnv = 'paragonEnv',
  ParagonUser = 'paragonUser',
  TeamsLeaderboard = 'teamsLeaderboard',
  ResourceFactors = 'resourceFactors',
  PlanItemsFindingsCount = 'planItemsFindingsCount',
  FindingsCount = 'findingsCount',
  Findings = 'findings',
  UserVendors = 'userVendors',
  GitlabGroups = 'gitlabGroups',
  GitlabSubgroups = 'gitlabSubgroups',
  GitlabProjects = 'gitlabProjects',
  QuickstartChecks = 'quickstartChecks',
  AssetsStatistics = 'assetsStatistics',
  AssetFilterValues = 'assetFilterValues',
  Assets = 'assets',
  AssetsCount = 'assetsCount',
  InitialScanStats = 'initialScanStats',
  IgnoredIssuesMetric = 'ignoredIssuesMetric',
  Mttr = 'mttr',
  OrgScoreTrending = 'orgScoreTrending',
  PrsStats = 'prsStats',
  TenantSecrets = 'tenantSecrets',

  // policies
  policiesTemplate = 'policiesTemplate',
  policyRules = 'policyRules',
  FetchFindingTeamsOptions = 'fetchFindingTeamsOptions',
  FetchFindingTypesOptions = 'fetchFindingTypesOptions',
  FetchAssetTypesOptions = 'fetchAssetTypesOptions',
  FetchAssetNamesOptions = 'fetchAssetNamesOptions',
  FetchPriorityFactorsOptions = 'fetchPriorityFactorsOptions',
  FetchSCMMembers = 'fetchSCMMembers',

  // workflows
  Workflows = 'workflows',
  Workflow = 'workflow',
  WorkflowHistory = 'workflowHistory',
  WorkflowsStepsOptions = 'workflowsStepsOptions',
}
