import { useEffect, useState, useCallback } from 'react';

import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { useActivatePlanItem } from 'context/PlansContext/hooks/useActivatePlanItem';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import { constants } from 'globalConstants';
import { logError } from 'services/logger/logger';
import { useSnackBar } from 'utils/hooks/useSnackBar';

export const useActivateSBOM = () => {
  const { activatePlanItem } = useActivatePlanItem();
  const { showSnackBar } = useSnackBar();
  const { plans, fetchPlanDetails, fetchPlans, hasFetchedPlans } = usePlansContext();
  const { hasPlanFetched, setInitialPlan } = usePlanInstanceContext();
  const [isActivating, setIsActivating] = useState<boolean>(false);

  const raiseErrorToast = useCallback(() => {
    showSnackBar({
      title: 'toasts.failedApiRequest.title',
      description: 'toasts.failedApiRequest.subtitle',
      type: 'error',
    });
  }, [showSnackBar]);

  useEffect(() => {
    if (!plans[constants.PLAN_SLUG.MVS]?.hasFetchedItems) {
      fetchPlanDetails(constants.PLAN_SLUG.MVS).then((hasCompleted) => {
        if (!hasCompleted) {
          logError('Could not fetch plan details for default plan');
          raiseErrorToast();
        }
      }).catch(() => {
        logError('Could not fetch plan details for default plan');
        raiseErrorToast();
      });
    }
  }, [fetchPlanDetails, fetchPlans, hasFetchedPlans, isActivating, plans, raiseErrorToast]);

  useEffect(() => {
    if (!hasPlanFetched) {
      setInitialPlan();
    }
  }, [hasPlanFetched, setInitialPlan]);

  const planItemDetails = plans?.[constants.PLAN_SLUG.MVS]?.items?.[constants.PLAN_ITEMS_SLUG.SBOM];
  const activate = useCallback(() => {
    if (!planItemDetails) {
      raiseErrorToast();
      logError('Could not find plan item details for item-software-bill-of-materials');
      return;
    }
    activatePlanItem(constants.PLAN_ITEMS_SLUG.SBOM, planItemDetails);
    setIsActivating(true);
  }, [activatePlanItem, planItemDetails, raiseErrorToast]);

  return [activate, isActivating] as const;
};
