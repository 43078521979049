import { paragon } from '@useparagon/connect';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './VendorPicker.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

interface VendorPickerCardProps {
  vendor: string;
  isSelected: boolean
  isDisabled: boolean;
  onCardClick: (vendor: string) => void;
  isThirdParty?: boolean;
}

export const VendorPickerCard: FC<VendorPickerCardProps> = ({
  vendor,
  isSelected,
  isDisabled,
  onCardClick,
  isThirdParty = true,
}) => {
  const { INTEGRATIONS } = constants.routes;
  const { isLoading } = useParagonAuth();
  const { getIconByVendor, getVendorDisplayName } = useDetailsByVendor();
  const navigate = useNavigate();

  let className = styles.card;

  if (isSelected) {
    className = styles.cardSelected;
  } else if (isDisabled) {
    className = styles.cardDisabled;
  }

  const onClick = () => {
    onCardClick(vendor);
  };

  const onClickConnect = () => {
    if (isThirdParty && !isLoading) {
      paragon.connect(vendor, {});
    } else {
      navigate(`/${INTEGRATIONS}`);
    }
  };

  const icon = getIconByVendor(vendor, undefined, true);

  return (
    <div
      key={vendor}
      className={className}
      data-testid={`VendorPickerCard-${vendor}`}
      onClick={isDisabled ? undefined : onClick}
      role={isDisabled ? undefined : 'button'}
      tabIndex={isDisabled ? undefined : 0}
    >

      <div className={styles.iconAndVendor}>
        <JitIcon className={styles.icon} icon={icon} size={33} />

        <div className={styles.vendorNameWrapper}>
          <JitText bold text={getVendorDisplayName(vendor)} />
        </div>
      </div>

      {isDisabled && (
        <JitButton disableRipple noHover onClick={onClickConnect}>
          <JitText color={colors.blue02} text='cards.buttons.connect' />
        </JitButton>
      )}
    </div>
  );
};
