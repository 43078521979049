import { FC, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './WorkflowGraph.module.scss';

import { JittyNoWorkflow } from 'assets';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitGraph } from 'components/JitGraph/JitGraph';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { AddStepNode } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/AddStepNode';
import { CustomEdge } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/CustomEdge';
import { CustomNode } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/CustomNode';
import { getLayoutedElements } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/visualUtils';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { translateWorkflowToGraph } from 'pages/WorkflowsPage/EditWorkflowPage/utils/translateWorkflowToGraph';
import colors from 'themes/colors.module.scss';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

const edgeTypes = {
  customEdge: CustomEdge,
};
const nodeTypes = {
  customNode: CustomNode,
  addStepNode: AddStepNode,
};

export const WorkflowGraph: FC = () => {
  const {
    workflow,
    isLoadingWorkflow,
    isLoadingStepsOptions,
    getStepNextOptions,
    isFailedFetchingWorkflow,
  } = useEditWorkflowContext();
  const { getIconByVendor } = useDetailsByVendor();
  const navigate = useNavigate();
  const { WORKFLOWS } = constants.routes;

  const onClickBack = useCallback(() => {
    navigate(`/${WORKFLOWS}`);
  }, [navigate, WORKFLOWS]);

  const graph = useMemo(() => {
    if (!workflow) {
      return {
        nodes: [],
        edges: [],
      };
    }
    return translateWorkflowToGraph(workflow, getStepNextOptions, getIconByVendor);
  }, [getStepNextOptions, getIconByVendor, workflow]);

  const {
    nodes,
    edges,
  } = graph;
  const isLoading = (isLoadingWorkflow || isLoadingStepsOptions) && !isFailedFetchingWorkflow;
  return (
    <>
      <JitGraph
        backgroundColor={colors.appBgColor}
        edges={edges}
        edgeTypes={edgeTypes}
        getLayoutedElements={getLayoutedElements}
        isLoading={isLoading}
        maxZoom={2}
        minZoom={0.6}
        nodes={nodes}
        nodeTypes={nodeTypes}
      />

      {isFailedFetchingWorkflow && (
        <div className={styles.noWorkflowWrapper}>
          <JitEmpty
            description='pages.workflows.editWorkflowPage.noWorkflowFound.subtitle'
            descriptionColor={colors.lightGray}
            height='150px'
            icon={JittyNoWorkflow}
            showJitty
            title='pages.workflows.editWorkflowPage.noWorkflowFound.title'
            titleColor={colors.white}
            width='154px'
          />

          <JitGradientButton
            height={45}
            onClick={onClickBack}
            width={200}
          >
            <JitText horizontalAlign='center' text='pages.workflows.editWorkflowPage.noWorkflowFound.goBackButtonText' />
          </JitGradientButton>
        </div>
      )}
    </>
  );
};
