import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';

import { PlanItemActiveLabel, PlanItemConfigureButton } from '..';

import styles from './PlanItemActions.module.scss';

import { useGetPlanItemConfigurationsStatus } from 'context/ConfigurationsContext/hooks';
import { ActivatePlanItemButton } from 'pages/PlanPage/components/ActivatePlanItemButton/ActivatePlanItemButton';
import { ItemSlugOptions } from 'pages/PlanPage/components/PlanItemIntroDialog';
import { IPlanItemDetails } from 'types/interfaces';

interface Props {
  planSlug: string;
  planItem: IPlanItemDetails
  handleConfigure: (planItem: IPlanItemDetails) => void;
  setIntroItem: Dispatch<SetStateAction<ItemSlugOptions | null>>;
}

export const PlanItemActions: FC<Props> = ({ planSlug, planItem, handleConfigure, setIntroItem }) => {
  const { isConfigurable } = useGetPlanItemConfigurationsStatus();
  const isItemConfigurable = useMemo(() => isConfigurable(planItem), [isConfigurable, planItem]);

  const renderActionButton = useCallback((shouldConfigure: boolean) => (
    (shouldConfigure) ? (
      <PlanItemConfigureButton
        handleConfigure={handleConfigure}
        isPlanItemActive={planItem.is_active}
        longText={false}
        planItem={planItem}
        variant='contained'
      />
    ) : (
      <ActivatePlanItemButton
        planItem={planItem}
        planSlug={planSlug}
        setIntroItem={setIntroItem}
        variant='contained'
      />
    )
  ), [handleConfigure, planItem, planSlug, setIntroItem]);

  return (
    <div className={styles.actionsContainer}>
      {planItem.is_active ? <PlanItemActiveLabel /> : renderActionButton(isItemConfigurable)}
    </div>
  );
};
