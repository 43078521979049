/* eslint-disable react/jsx-max-depth */
import { t } from 'i18next';
import { debounce } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { getSubGroupPath } from '../ChooseGitlabGroup/utils';
import commonStyles from '../common.module.scss';

import styles from './ChooseGitlabProjects.module.scss';
import { getSelectedProjectsText } from './utils';

import { GitlabIcon } from 'assets';
import { IGitlabIntegrationWizardStepStatus } from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { CoverageSelectionDialog } from 'components/JitDialogs/CoverageSelectionDialog/CoverageSelectionDialog';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitRadioButton } from 'components/JitRadioButton/JitRadioButton';
import { JitText } from 'components/JitText/JitText';
import { allProjectCoverageType, IGroup, IProject, selectedProjectCoverageType, useGitlabService } from 'services/GitlabService/useGitlabService';
import colors from 'themes/colors.module.scss';
import { Queries } from 'types/enums/Queries';
import { ActionButtonVariant, GitlabAccessLevel } from 'types/interfaces';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';
import { useSnackBar } from 'utils/hooks/useSnackBar';

interface Props {
  selectedGroup: IGroup;
  selectedRole: GitlabAccessLevel;
  status: IGitlabIntegrationWizardStepStatus;
  incrementStepIndex: () => void;
}

const PER_PAGE_LIMIT = 20;

export const ChooseGitlabProjects: FC<Props> = ({ selectedGroup, selectedRole, status, incrementStepIndex }: Props) => {
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [selectedProjects, setSelectedProjects] = useState<IProject[]>([]);
  const [selectedSubgroups, setSelectedSubgroups] = useState<IGroup[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAssetsDialog, setShowAssetsDialog] = useState(false);
  const { showSnackBar } = useSnackBar();

  const { fetchProjects, fetchSubgroups, updateInstallationProjects } = useGitlabService();

  const handleApiError = useCallback(() => {
    showSnackBar({
      title: t('GitlabIntegrationWizard.chooseGitlabProjects.fetchingError'),
      description: t('GitlabIntegrationWizard.chooseGitlabProjects.fetchingErrorDescription'),
      type: 'error',
    });
  }, [showSnackBar]);

  const {
    data: projectsData,
    isLoading: isProjectsLoading,
    refetch: refetchProjects,
  } = useQuery(
    [Queries.GitlabProjects, selectedGroup.id, searchTerm],
    () => fetchProjects(selectedGroup.id, {
      page: 1,
      per_page: PER_PAGE_LIMIT,
      search: searchTerm,
    }),
    {
      onError: handleApiError,
    },
  );

  const {
    data: subgroupsData,
    isLoading: isSubgroupsLoading,
    refetch: refetchSubgroups,
  } = useQuery(
    [Queries.GitlabSubgroups, selectedGroup.id, searchTerm],
    async () => {
      const response = await fetchSubgroups(selectedGroup.id, {
        per_page: PER_PAGE_LIMIT,
        page: 1,
        search: searchTerm,
        min_access_level: selectedRole,
      });
      return camelizeSnakeCaseKeys(response?.data.groups || []) as IGroup[];
    },
    {
      onError: handleApiError,
    },
  );

  const projectOptions = useMemo(
    () => (projectsData || []).map((project) => ({
      ...project,
      pathWithNamespace: project?.pathWithNamespace.split('/').slice(1).join('/'),
    })).sort((a, b) => (a?.pathWithNamespace ?? '').localeCompare(b?.pathWithNamespace ?? '')) ?? [],
    [projectsData],
  );

  const subgroupOptions = useMemo(
    () => (subgroupsData || []).map((subgroup) => ({
      ...subgroup,
      fullPath: getSubGroupPath(subgroup?.fullPath),
    })).sort((a, b) => (a?.fullPath ?? '').localeCompare(b?.fullPath ?? '')) ?? [],
    [subgroupsData],
  );

  const debouncedInputChange = debounce((value: string) => {
    refetchProjects();
    refetchSubgroups();
    setSearchTerm(value);
  }, 300);

  const handleInputChange = useCallback((value: string) => {
    if (value.length < 3 && value.length > 0) {
      return;
    }

    debouncedInputChange(value);
  }, [debouncedInputChange]);

  const handleAssetsDialogConfirm = useCallback((projects: IProject[], groups: IGroup[]) => {
    setSelectedProjects(projects);
    setSelectedSubgroups(groups);
    setShowAssetsDialog(false);
    setIsAllSelected(false);
  }, []);

  const onContinueButtonClick = useCallback(async () => {
    if (status === IGitlabIntegrationWizardStepStatus.COMPLETE) {
      incrementStepIndex();
      return;
    }

    setIsLoading(true);
    const reqBody = isAllSelected
      ? { project_coverage_type: allProjectCoverageType }
      : {
        project_coverage_type: selectedProjectCoverageType,
        projects: selectedProjects.map(({ id, name }) => ({
          project_id: id,
          project_name: name,
        })),
        subgroups: selectedSubgroups.map((group) => `${selectedGroup.path}/${group.fullPath}`),
      };

    await updateInstallationProjects(selectedGroup.id, reqBody);
    setIsLoading(false);
    incrementStepIndex();
  }, [status, isAllSelected, selectedProjects, selectedSubgroups, updateInstallationProjects, selectedGroup.id, selectedGroup.path, incrementStepIndex]);

  const areProjectsSelected = useMemo(() => selectedProjects.length > 0 || selectedSubgroups.length > 0, [selectedProjects, selectedSubgroups]);

  const isContinueButtonDisabled = useMemo(
    () => !isAllSelected
      && selectedProjects.length === 0
      && selectedSubgroups.length === 0
      && status !== IGitlabIntegrationWizardStepStatus.COMPLETE,
    [isAllSelected, selectedProjects.length, selectedSubgroups.length, status],
  );

  return (
    <DialogContentWrapper
      actionButtons={[
        {
          label: 'GitlabIntegrationWizard.chooseGitlabProjects.buttonText',
          disabled: isContinueButtonDisabled,
          handleClick: onContinueButtonClick,
          isLoading,
        },
      ]}
      icon={GitlabIcon}
    >
      <div className={`${commonStyles.dotJitTextBox} ${styles.content}`}>
        <JitText bold size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.title' />

        <div className={styles.radioButtonsContainer}>
          <div
            className={styles.buttonContainer}
            onClick={() => setIsAllSelected(true)}
            role='button'
            tabIndex={0}
          >
            <JitRadioButton checked={isAllSelected} />

            <div className={styles.buttonWrapper}>
              <JitText bold size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.all.label' />

              <JitText muted size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.all.description' />
            </div>
          </div>

          <div
            className={styles.buttonContainer}
            onClick={() => setIsAllSelected(false)}
            role='button'
            tabIndex={0}
          >
            <JitRadioButton checked={!isAllSelected} />

            <div className={styles.buttonWrapper}>
              <JitText bold size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.label' />

              <JitText muted size='l' text='GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.description' />

              {status !== IGitlabIntegrationWizardStepStatus.COMPLETE && !isAllSelected && (
              <div className={styles.chooseProjectsButtonContainer}>
                {!areProjectsSelected && (
                <JitActionButton
                  actionButton={{
                    variant: ActionButtonVariant.OUTLINED,
                    label: 'GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.chooseProjectsButton',
                    handleClick: () => setShowAssetsDialog(true),
                  }}
                />
                )}

                {areProjectsSelected && (
                <div className={styles.selectedProjectsTextContainer}>
                  <JitText size='l' text={getSelectedProjectsText(selectedProjects, selectedSubgroups)} />

                  <JitText bold color={colors.blue02} onClick={() => setShowAssetsDialog(true)} size='l' text='Edit' />
                </div>
                )}
              </div>
              )}
            </div>

          </div>
        </div>
      </div>

      {showAssetsDialog && (
        <CoverageSelectionDialog
          alreadySelectedProjects={selectedProjects}
          alreadySelectedSubgroups={selectedSubgroups}
          handleSearch={handleInputChange}
          isLoading={isProjectsLoading || isSubgroupsLoading}
          onClose={() => setShowAssetsDialog(false)}
          onConfirm={handleAssetsDialogConfirm}
          projectsResults={projectOptions as IProject[]}
          subgroupsResults={subgroupOptions as IGroup[]}
        />
      )}
    </DialogContentWrapper>
  );
};
