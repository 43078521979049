import { t } from 'i18next';

import { IGroup, IProject } from 'services/GitlabService/useGitlabService';

export const getSelectedProjectsText = (selectedProjects: IProject[], selectedSubgroups: IGroup[]): string => {
  const subgroupsPlural = selectedSubgroups.length > 1
    ? t('GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.subgroupsPlural')
    : t('GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.subgroupsSingular');

  const projectsPlural = selectedProjects.length > 1
    ? t('GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.projectsPlural')
    : t('GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.projectsSingular');

  if (selectedProjects.length === 0 && selectedSubgroups.length === 0) return '';

  if (selectedProjects.length === 0) {
    return t(
      'GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.selectedProjectsOrSubgroupsText',
      {
        count: selectedSubgroups.length,
        plural: subgroupsPlural,
      },
    );
  }

  if (selectedSubgroups.length === 0) {
    return t(
      'GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.selectedProjectsOrSubgroupsText',
      {
        count: selectedProjects.length,
        plural: projectsPlural,
      },
    );
  }

  return t(
    'GitlabIntegrationWizard.chooseGitlabProjects.radioButtons.selected.selectedProjectsAndSubgroupsText',
    {
      projectsCount: selectedProjects.length,
      projectsPlural,
      subgroupsCount: selectedSubgroups.length,
      subgroupsPlural,
    },
  );
};
