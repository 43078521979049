import { camelCase, startCase, isEmpty } from 'lodash';
import { FC } from 'react';

import {
  getAssetLinkFromPipelineParams,
  getTextFromPipelineParams,
} from '../../utils/utilsNew';

import styles from './ResourceNameAndTypeCellV3.module.scss';

import { Empty } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { app } from 'locale/en';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IWorkflowNew } from 'types/interfaces';
import { stopPropagation } from 'utils';
import { useDetailsByVendor } from 'utils/hooks/useDetailsByVendor';

interface Props {
  eventType: string;
  environment: string;
  vendor?: string;
  owner?: string;
  assetName?: string;
  assetType?: string;
  id?: string;
  disableLink?: boolean;
  workflows?: IWorkflowNew;
}

export const ResourceNameAndTypeHeaderV3: FC<Props> = ({ eventType, environment, assetName, assetType, id, vendor, owner, disableLink, workflows }) => {
  const { t } = i18n;
  const triggersDisplayNames = app.pages.pipelines.trigger.displayNamesV3;
  const triggerDisplayText = triggersDisplayNames[camelCase(eventType) as keyof typeof triggersDisplayNames];
  const environmentTitleCase = startCase(environment);
  const getResourceNameText = getTextFromPipelineParams(vendor, owner, assetName, assetType, eventType);
  const { getIconByVendor } = useDetailsByVendor();
  const getResourceNameTextElement = (isClickable: boolean) => (
    <TooltipOnlyOnOverflow tooltipText={getResourceNameText}>
      <JitText
        addUnderlineOnHover={isClickable}
        bold
        className={styles.overflowEllipsis}
        data-testid='resource-name'
        noWrap
        overflowHiddenEllipsis
        size='xl'
        text={getResourceNameText}
      />
    </TooltipOnlyOnOverflow>
  );

  const getResourceNameWithIdElement = (displayId: string, displayAssetName: string) => (
    <JitTooltip
      childrenWrapperClassName={styles.tooltipChildren}
      followCursor={false}
      placement='top'
      title={displayId}
    >
      <JitText
        bold
        className={styles.overflowEllipsis}
        data-testid='resource-name'
        noWrap
        params={{ assetName: t(displayAssetName) as string,
          id: displayId }}
        size='l'
        text='pages.pipelines.pipelinesTable.resourceNameWithId'
      />
    </JitTooltip>
  );

  const getVendorByWorkflowAssets = () => {
    if (!workflows) {
      return undefined;
    }
    const firstWorkflow = Object.values(workflows)[0];
    if (!firstWorkflow) {
      return undefined;
    }
    const firstAsset = firstWorkflow && firstWorkflow.assets && Object.values(firstWorkflow.assets)[0];
    return getIconByVendor(firstAsset.vendor, assetType);
  };

  const vendorIcon = (getIconByVendor(vendor, assetType) || getVendorByWorkflowAssets());
  const getResourceNameElement = () => {
    if (id && assetName) {
      return getResourceNameWithIdElement(id, assetName);
    }
    const resourceLink = getAssetLinkFromPipelineParams(vendor, owner, assetName, assetType);
    const shouldBeClickable = (!disableLink && !isEmpty(resourceLink));
    if (shouldBeClickable) {
      return (
        <JitLink
          data-testid='resource-name-link'
          href={resourceLink}
          onClick={stopPropagation}
          rel='noreferrer'
          style={{ textDecoration: 'none',
            overflow: 'hidden',
            textOverflow: 'ellipsis' }}
          target='_blank'
        >
          {getResourceNameTextElement(true)}
        </JitLink>
      );
    }
    return getResourceNameTextElement(false);
  };

  return (
    <div>
      <div className={styles.headerWrapper}>
        <JitIcon data-testid='vendor-icon' icon={vendorIcon || Empty} size={20} />

        {isEmpty(getResourceNameText) ? (
          <div className={styles.loadingWrapper}>
            <JitText bold data-testid='no-resource-name-text' size='m' text='pages.pipelines.pipelinesTable.loading' />

            <JitText bold className={styles.dotFlashing} size='m' text=' ' />
          </div>
        ) : (
          getResourceNameElement())}
      </div>

      {eventType && (
      <JitText
        className={styles.triggerDisplayText}
        color={colors.lightGray}
        data-testid='event-type'
        params={{ environment: environmentTitleCase }}
        size='m'
        text={triggerDisplayText}
      />
      )}
    </div>
  );
};
