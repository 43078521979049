import styles from './AssetSection.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { SelectableAsset } from 'components/JitDialogs/CoverageSelectionDialog/hooks/useAssetSelection';
import { JitText } from 'components/JitText/JitText';
import { CollapseSection } from 'pages/OverviewPage/components/CollapseSection/CollapseSection';
import colors from 'themes/colors.module.scss';
import { stopPropagation } from 'utils';

const getItemName = (item: SelectableAsset): string => ('fullPath' in item ? item.fullPath : item.pathWithNamespace);

interface Props {
  title: string;
  items: SelectableAsset[];
  onItemCheck: (id: string, checked: boolean) => void;
  isLoading?: boolean;
  dataTestId?: string;
}

export const AssetSection = ({ title, items, onItemCheck, isLoading, dataTestId }: Props) => (
  <CollapseSection
    header={(
      <div className={styles.headerContainer}>
        <JitText text={title} />

        {isLoading ? (
          <CircularLoadingSpinner size='small' variant='simple' />
        ) : (
          <JitText text={`(${items.length})`} />
        )}
      </div>
      )}
    testId={dataTestId}
  >
    <div className={styles.contentContainer}>
      <JitText bold className={styles.sectionHeader} muted size='xs' text='GitlabIntegrationWizard.chooseGitlabProjects.coverageSelection.nameHeader' />

      {items.map((item) => (
        <div key={item.id} className={styles.itemContainer} data-testid={`AssetSection - ${item.name}`}>
          <div className={styles.cell}>
            <JitCheckbox
              checked={item.isSelected}
              data-testid='asset-checkbox'
              disabled={false}
              id={item.id}
              onChange={(_, checked) => onItemCheck(item.id, checked)}
              onClick={stopPropagation}
            />

            <div className={styles.archiveLabelWrapper}>
              <JitText bold color={colors.lightGray} horizontalAlign='center' size='s' text={getItemName(item)} />
            </div>
          </div>
        </div>
      ))}
    </div>
  </CollapseSection>
);
