import AddIcon from '@mui/icons-material/Add';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { isArray, isEmpty } from 'lodash';
import { FC, useState, useRef } from 'react';

import { useInputWidth } from '../hooks/useInputWidth';
import { useUpdateElementWidth } from '../hooks/useUpdateElementWidth';

import { JitAutoCompleteTags } from './components/AutoCompleteTags';
import { CustomPopper } from './components/CustomPopper';
import { DefaultTextField } from './components/DefaultTextField';
import { LightTextField } from './components/LightTextField';
import defaultStyles from './JitAutoCompleteForm.module.scss';
import lightStyles from './JitAutoCompleteFormLight.module.scss';

import { CircularProgressBar } from 'components/CircularProgressBar/CircularProgressBar';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { stopPropagation } from 'utils';

export type AutocompleteVariant = 'default' | 'light';

const variantToStyle: Record<AutocompleteVariant, CSSModuleClasses> = {
  default: defaultStyles,
  light: lightStyles,
};

const variantToTextField: Record<AutocompleteVariant, React.ElementType> = {
  default: DefaultTextField,
  light: LightTextField,
};

interface IProps<T> {
  placeHolder?: string
  options?: T
  getOptionLabel?: (v: T) => string
  getOptionKey?: (v: T) => string
  selectedValue: T
  setSelectedValue: (value: T) => void
  endAdornment?: JSX.Element
  freeSolo?: boolean
  isSingleValue?: boolean
  isLoading?: boolean
  loadingText?: string
  disabled?: boolean
  disableCloseOnSelect?: boolean
  onScroll?: (event: React.UIEvent<HTMLUListElement>) => void
  onInputChange?: (value: string) => void
  noOptionsText?: string
  clearOnBlur?: boolean
  autoFocus?: boolean
  type?: string
  variant?: AutocompleteVariant
  showArrow?: boolean
  maxDisplayedTags?: number;
  fitContent?: boolean;
  fontSize?: number;
  width?: number;
  maxInputWidth?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<T = any> = IProps<T>;

export const JitAutoCompleteForm: FC<Props> = ({
  placeHolder,
  options,
  getOptionLabel,
  getOptionKey,
  selectedValue,
  setSelectedValue,
  endAdornment,
  freeSolo,
  isSingleValue,
  isLoading,
  loadingText,
  disabled,
  disableCloseOnSelect,
  onScroll,
  onInputChange,
  noOptionsText,
  clearOnBlur = true,
  autoFocus = true,
  type = 'text',
  variant = 'default',
  showArrow = true,
  maxDisplayedTags,
  width,
  fitContent = false,
  fontSize = 16,
  maxInputWidth,
}) => {
  const [textInput, setTextInput] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);
  const inputWidth = useUpdateElementWidth(inputRef);
  const { minInputWidth, optionsWidth } = useInputWidth({
    textInput,
    placeHolder,
    fitContent,
    endAdornment,
    fontSize,
    options,
    getOptionLabel,
    maxInputWidth,
  });

  const handleAddFreeSoloValue = () => {
    const trimmed = textInput.trim();
    if (!trimmed) return;

    if (isSingleValue) {
      setSelectedValue(trimmed);
    } else {
      const currentVal = Array.isArray(selectedValue) ? selectedValue : [];
      setSelectedValue([
        ...currentVal,
        ...(!currentVal.includes(trimmed) ? [trimmed] : []),
      ]);
    }
    setTextInput('');
  };

  const handleInputChange = (event: React.SyntheticEvent<Element, Event> | null, value: string) => {
    if (event && event.type === 'change') {
      onInputChange?.(value);
    }

    if (isSingleValue && !options) {
      setSelectedValue(value);
    }
    setTextInput(value);
  };

  const styles = variantToStyle[variant];
  const JitTextField = variantToTextField[variant];

  const noOptionsClass = options ? styles.noOptions : styles.hideNoOptions;
  const autoCompleteRootClass = disabled ? `${styles.autoComplete} ${styles.disabledAutoComplete}` : styles.autoComplete;

  const endAdornmentComponent = (params: AutocompleteRenderInputParams) => {
    if (isLoading) {
      return <CircularProgressBar className={styles.loadingProgress} size='18px' />;
    }

    if (freeSolo && !disabled && textInput) {
      return (
        <>
          <IconButton onClick={handleAddFreeSoloValue} size='small'>
            <AddIcon sx={{ fontSize: 14 }} />
          </IconButton>

          {!!options && showArrow && (endAdornment || params.InputProps.endAdornment)}
        </>
      );
    }

    return !!options && showArrow && (endAdornment || params.InputProps.endAdornment);
  };

  return (
    <FormControl
      className={styles.formControl}
      data-testid='formControl'
      fullWidth
      onClick={stopPropagation}
    >
      <Autocomplete
        autoHighlight
        classes={{
          noOptions: noOptionsClass,
          loading: styles.loading,
          root: autoCompleteRootClass,
        }}
        clearOnBlur={clearOnBlur}
        disableClearable
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        filterSelectedOptions
        freeSolo={freeSolo}
        getOptionLabel={getOptionLabel}
        ListboxProps={{
          className: styles.listBox,
          onScroll,
        }}
        loading={isLoading}
        loadingText={loadingText}
        multiple={!isSingleValue}
        noOptionsText={noOptionsText || undefined}
        onChange={(event, value) => setSelectedValue(value)}
        onInputChange={handleInputChange}
        options={options || []}
        // eslint-disable-next-line react/no-unstable-nested-components
        PopperComponent={(props) => (
          // width + 16px to account for padding
          <CustomPopper {...props} inputWidth={width ? width + 16 : inputWidth || undefined} variant={variant} />
        )}
        renderInput={(params) => (
          <JitTextField
            {...params}
            ref={inputRef}
            autoFocus={autoFocus}
            className={styles.textField}
            data-testid='share-input'
            hasTags={isArray(selectedValue) && !isEmpty(selectedValue)}
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              ...params.InputProps,
              style: { fontSize },
              endAdornment: endAdornmentComponent(params),
              sx: {
                '&::placeholder': {
                  fontSize,
                },
              },
            }}
            minInputWidth={minInputWidth}
            optionsWidth={optionsWidth}
            placeholder={textInput || !isEmpty(selectedValue) ? ' ' : placeHolder}
            size='small'
            type={type}
          />
        )}
        renderOption={(props, option) => {
          const value = getOptionLabel ? getOptionLabel(option) : option;
          return (
            <Box
              {...props}
              key={getOptionKey ? getOptionKey(option) : value}
              className={styles.optionBox}
              component='li'
              data-testid='li-box-option'
            >
              <TooltipOnlyOnOverflow tooltipText={value}>
                <JitText noWrap overflowHiddenEllipsis text={value} />
              </TooltipOnlyOnOverflow>
            </Box>
          );
        }}
        renderTags={(values, getTagProps) => (
          <JitAutoCompleteTags
            getOptionLabel={getOptionLabel}
            getTagProps={getTagProps}
            maxDisplayedTags={maxDisplayedTags}
            values={values}
          />
        )}
        value={selectedValue || null}
      />
    </FormControl>
  );
};
