import { FC, KeyboardEvent } from 'react';

import styles from './IconButtonsGrid.module.scss';

import { DisabledElement } from 'components/DisabledElement/DisabledElement';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';
import { ISvg } from 'types/interfaces';

export interface IconButtonItem {
  key: string;
  icon: FC<ISvg>;
  onClick: () => void;
  isDisabled: boolean;
  isSelected: boolean;
  text: string;
}

interface IconButtonsGridProps {
  items: IconButtonItem[];
  isLoading?: boolean;
}

export const IconButtonsGrid: FC<IconButtonsGridProps> = ({ items, isLoading = false }) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>, onClick: () => void) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  return (
    <div className={styles.root}>
      {items.map(({ key, icon, onClick, isDisabled, isSelected, text }) => (
        !isLoading ? (
          <DisabledElement key={key} isDisabled={isDisabled}>
            <div
              className={isSelected ? styles.selectedButton : styles.button}
              data-testid={`icon-button-${key}`}
              onClick={onClick}
              onKeyDown={(e) => handleKeyDown(e, onClick)}
              role='button'
              tabIndex={0}
            >
              <JitIcon className={styles.icon} icon={icon} size={50} />

              <JitText text={text} />
            </div>
          </DisabledElement>
        ) : (
          <LoadingSkeleton key={key} className={styles.skeleton} />
        )
      ))}
    </div>
  );
};
