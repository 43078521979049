import { FC } from 'react';

import { SelectionListCustomSCM } from '../../components/SelectionList/SelectionListCustomSCM';

import {
  CustomAWSButton,
  SelectionList,
  SelectionListCustomAws,
  SelectionListCustomCloud,
  TitleLinkDetails,
} from 'components/JitDialogs/AssetsManagementDialog/components';
import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import { config } from 'config';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { getIntegrationTemplateVendor } from 'pages/IntegrationsPage/utils/getIntegrationTemplateVendor';
import { AssetType, Vendor } from 'types/enums';
import { IAsset, IInstallation, IInstallationModelVendorAttributes } from 'types/interfaces';

const shouldAssetAppearInTab = (assetVendor: string, targetVendor: Vendor): boolean => assetVendor === targetVendor || getIntegrationTemplateVendor(assetVendor) === targetVendor;

interface TabDetails {
  wholeSelectionCheckboxText: string; // Will be passed to the <JitText /> component as the text prop
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  SelectionListComponent: FC<any>;
  titleLinkDetails?: TitleLinkDetails
  CustomLowerBarLeftComponent?: FC;

  shouldAppearInCurrentSelectedTab: (asset: IAsset) => boolean,
}

type TabDetailsMap = Partial<{ [key in TABS]: TabDetails }>;

const buildSCMTabs = (scmInstallations: IInstallation[]): TabDetailsMap => {
  const tabDetails: TabDetailsMap = {};

  scmInstallations.forEach((installation) => {
    const vendor = installation.vendor as TABS;
    const vendorAttributes = installation.vendor_attributes as IInstallationModelVendorAttributes;
    tabDetails[vendor] = {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${vendor}`,
      SelectionListComponent: SelectionListCustomSCM,
      ...(vendor === TABS.GITHUB && {
        titleLinkDetails: {
          text: 'dialogs.assetsManagement.checkPermissions',
          url: vendorAttributes?.html_url
            || config.getGithubAppPermissionsUrl(installation.owner, installation.installation_id),
        },
      }),
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => shouldAssetAppearInTab(asset.vendor, installation.vendor as Vendor) && asset.asset_type === AssetType.REPO,
    };
  });

  return tabDetails;
};

// This mapping should hold all the different data related to each tab as well as special
// data relevant to specific tabs.
export const useAssetsManagementTabDetailsByTab = (index: TABS): TabDetails => {
  const { scmInstallations } = useTenantContext();
  const tabDetails = buildSCMTabs(scmInstallations);
  const defaultTabDetails: TabDetails = {
    wholeSelectionCheckboxText: '',
    SelectionListComponent: () => null,
    shouldAppearInCurrentSelectedTab: () => false,
  };

  return {
    ...tabDetails,
    [TABS.AWS]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.AWS}`,
      CustomLowerBarLeftComponent: CustomAWSButton,
      SelectionListComponent: SelectionListCustomAws,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => shouldAssetAppearInTab(asset.vendor, Vendor.AWS),
    },
    [TABS.GCP]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.GCP}`,
      SelectionListComponent: SelectionListCustomCloud,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => shouldAssetAppearInTab(asset.vendor, Vendor.GCP),
    },
    [TABS.AZURE]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.AZURE}`,
      SelectionListComponent: SelectionListCustomCloud,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => shouldAssetAppearInTab(asset.vendor, Vendor.AZURE),
    },
    [TABS.WEB]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.WEB}`,
      SelectionListComponent: SelectionList,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => asset.asset_type === AssetType.WEB,
    },
    [TABS.API]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.API}`,
      SelectionListComponent: SelectionList,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => asset.asset_type === AssetType.API,
    },
  }[index] || defaultTabDetails;
};
