import { FC, useEffect } from 'react';

import { ReviewCard, Carousel } from './components';
import { loginTitle, reviews } from './constants';
import styles from './LoginPage.module.scss';
import { initializeFronteggUI } from './utils/fronteggDomUtils';

import { JitFavicon, LoginJitTeams, LoginLogos, LoginBg } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { useNotAuthenticatedAnalyticsClient } from 'context/AnalyticsContext/hooks/useNotAuthenticatedAnalyticsClient';
import colors from 'themes/colors.module.scss';

export const LoginPage: FC = () => {
  const { sendPageViewedEventNotAuthenticated } = useNotAuthenticatedAnalyticsClient();

  useEffect(() => {
    const cleanup = initializeFronteggUI();
    return cleanup;
  }, []);

  useEffect(() => {
    sendPageViewedEventNotAuthenticated('login', '/login');
  }, [sendPageViewedEventNotAuthenticated]);

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${LoginBg})` }}>
      <div className={styles.wrapper}>
        <div className={styles.favicon}>
          <JitFavicon
            fill={colors.jitPink}
          />
        </div>

        <JitText bold className='ReviewCard_Header' size='1vw' text={loginTitle} />

        <div className={styles.content}>
          <div className={styles.carouselContainer}>
            <Carousel
              ChildComponent={ReviewCard}
              options={reviews}
            />
          </div>

          <img
            alt='JitTeams Icon'
            className={styles.icon}
            src={LoginJitTeams}
          />
        </div>

      </div>

      <img alt='Logos Icon' src={LoginLogos} />
    </div>
  );
};
