import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import { ActionsContext } from 'context/ActionsContext/ActionsContext';
import { useHandleActionUpdatesWebSocketNotification } from 'context/ActionsContext/hooks';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { useFetchActionFilters, useFetchActions } from 'services/ActionService/useFetchActions';
import { WebSocketNotificationTopics } from 'types/enums';
import { ActionStatus } from 'types/interfaces';

export const ActionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    getActions,
    actions,
    isLoading,
    setActions,
  } = useFetchActions();
  const {
    getActionDistinctFilters,
    actionFilters,
    setActionFilters,
    initializeFilters,
    isFetchActionFiltersLoading,
    applyFilterToQueryParam,
  } = useFetchActionFilters();

  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleActionUpdatesWebSocketNotification } = useHandleActionUpdatesWebSocketNotification({
    actions,
    setActions,
  });

  const setActionStatus = useCallback((actionId: string, status: ActionStatus) => {
    setActions((prevState) => ({
      ...prevState,
      data: prevState?.data.map((prevAction) => {
        if (actionId === prevAction.id) {
          return {
            ...prevAction,
            status,
          };
        }

        return prevAction;
      }),
    }
    ));
  }, [setActions]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.ActionUpdates, handleActionUpdatesWebSocketNotification);
  }, [handleActionUpdatesWebSocketNotification, websocketSubscribe]);

  const value = useMemo(() => ({
    actions,
    isLoading,
    isFetchActionFiltersLoading,
    actionFilters,
    initializeFilters,
    setActionStatus,
    applyFilterToQueryParam,
    getActions,
    getActionDistinctFilters,
    setActions,
    setActionFilters,
  }), [actions, isLoading, actionFilters, isFetchActionFiltersLoading, applyFilterToQueryParam, initializeFilters,
    setActionStatus, getActions, getActionDistinctFilters, setActions, setActionFilters]);
  return (
    <ActionsContext.Provider value={value}>
      {children}
    </ActionsContext.Provider>
  );
};

