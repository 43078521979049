
import { FC, useEffect, useMemo } from 'react';

import { IntegrationWidget } from './components/IntegrationWidget/IntegrationWidget';
import { SecurityPlanTopRow } from './components/SecurityPlanTopRow/SecurityPlanTopRow';
import styles from './PlanPage.module.scss';

import { useFetchConfigurations } from 'context/ConfigurationsContext/hooks';
import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { useTenantContext } from 'context/TenantContext';
import { PlanPageBody } from 'pages/PlanPage/components';
import { Vendor } from 'types/enums';
import { IPlanDetails } from 'types/interfaces';
import { useScmIntegration } from 'utils/hooks/useScmIntegration';

interface Props {
  plan?: IPlanDetails;
}

export const PlanPage: FC<Props> = ({ plan }) => {
  const { setInitialPlan, hasPlanFetched } = usePlanInstanceContext();
  const { isAnyScmVendorIntegrated } = useTenantContext();

  const {
    ActiveSCMWizardComponent,
    setActiveSCMWizard,
    handleClose,
  } = useScmIntegration();

  const showScmWidget = useMemo(() => !isAnyScmVendorIntegrated, [isAnyScmVendorIntegrated]);

  useFetchConfigurations();

  useEffect(() => {
    if (!hasPlanFetched) {
      setInitialPlan();
    }
  }, [hasPlanFetched, setInitialPlan]);

  if (!plan) {
    return null;
  }

  return (
    <div className={styles.container}>
      <SecurityPlanTopRow plan={plan} />

      {showScmWidget
        ? (
          <IntegrationWidget handleIntegrateGithub={() => setActiveSCMWizard(Vendor.Github)} handleIntegrateGitlab={() => setActiveSCMWizard(Vendor.GITLAB)} />
        )
        : null}

      {ActiveSCMWizardComponent && <ActiveSCMWizardComponent handleClose={handleClose} />}

      <PlanPageBody planSlug={plan.slug} />
    </div>
  );
};
