/* eslint-disable no-nested-ternary */

import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useMemo } from 'react';

import { constants } from '../../globalConstants';

import { AWSIntegrationCard } from './components/integrationCards/AWSIntegrationCard/AWSIntegrationCard';
import { GithubIntegrationCard } from './components/integrationCards/GithubIntegrationCard/GithubIntegrationCard';
import { IntegrationCard } from './components/integrationCards/IntegrationCard/IntegrationCard';
import { SlackIntegrationCard } from './components/integrationCards/SlackIntegrationCard/SlackIntegrationCard';
import styles from './IntegrationsPage.module.scss';
import { getVariantTagArray } from './utils/variantTags';

import { AwsIcon, AzureIcon, DrataIcon, GcpIcon, GithubIcon, JiraIcon, JitIcon, LinearIcon, ShortcutIcon, SlackIcon } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useFilesContext } from 'context/FilesContext';
import { useIntegrationsContext } from 'context/IntegrationsContext';
import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import { CustomIntegrationCardDefaultProps, IntegrationTemplate, IntegrationType, IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useIntegrationsTemplate } from 'context/IntegrationsContext/templates/useIntegrationTemplates';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { Vendor } from 'types/enums';
import { FileEntityType } from 'types/enums/FileEntityType';

export const IntegrationsPage: FC = () => {
  const { isLoadingInstallation } = useTenantContext();
  const { integrations, isLoading } = useIntegrationsContext();
  const { filesMetadata } = useFilesContext();
  const { templates: integrationTemplates, groupedTemplates } = useIntegrationsTemplate(integrations);
  const { uiShowIntegrationSecurityTools, securityPlanShowPlanSoc2ByDrata } = useFlags();

  const { isLoading: isLoadingParagon } = useParagonAuth();

  const integrationFileLink = useMemo(() => filesMetadata?.find((file) => file.type
  === FileEntityType.INTEGRATION_FILE
  && file.vendorResponse?.htmlUrl), [filesMetadata]);

  // TODO: should be removed when we remove uiShowIntegrationSecurityTools FF
  const dynamicIntegrations = useMemo(() => {
    if (!integrationFileLink?.vendorResponse?.htmlUrl) {
      return [];
    }
    const integrationsList = [{
      vendor: Vendor.GCP,
      variant: IntegrationVendorType.cloud,
      icon: GcpIcon,
    },
    {
      vendor: Vendor.AZURE,
      variant: IntegrationVendorType.cloud,
      icon: AzureIcon,
    },
    {
      vendor: Vendor.Jira,
      variant: IntegrationVendorType.tms,
      icon: JiraIcon,
    },
    {
      vendor: Vendor.Shortcut,
      variant: IntegrationVendorType.tms,
      icon: ShortcutIcon,
    },
    {
      vendor: Vendor.LINEAR,
      variant: IntegrationVendorType.tms,
      icon: LinearIcon,
    }];

    if (securityPlanShowPlanSoc2ByDrata) {
      integrationsList.push({
        vendor: Vendor.Drata,
        variant: IntegrationVendorType.commercial,
        icon: DrataIcon,
      });
    }

    return integrationsList;
  }, [integrationFileLink, securityPlanShowPlanSoc2ByDrata]);

  const customIntegrationsMapping: Record<string, FC<CustomIntegrationCardDefaultProps>> = {
    aws: AWSIntegrationCard,
    github: GithubIntegrationCard,
    slack: SlackIntegrationCard,
  };

  if (isLoadingInstallation) {
    return (
      <div className={styles.page}>
        <LoadingBar data-testid='loadingTestId' />
      </div>
    );
  }

  const renderIntegrationCard = (integrationTemplate: IntegrationTemplate) => {
    if (integrationTemplate.integrationType === IntegrationType.custom) {
      const CustomIntegrationCard = customIntegrationsMapping[integrationTemplate.vendor];
      return (
        <CustomIntegrationCard
          key={integrationTemplate.key}
          icon={integrationTemplate.icon as FC || JitIcon}
          tags={getVariantTagArray(integrationTemplate.vendorType)}
          title={integrationTemplate.label}
        />
      );
    }
    return (
      <IntegrationCard
        key={integrationTemplate.key}
        description={integrationTemplate.shortDescription}
        icon={integrationTemplate.icon as FC || JitIcon}
        integrationFileLink={integrationTemplate.integrationType === IntegrationType.asCode
          ? integrationFileLink?.vendorResponse?.htmlUrl
          : undefined}
        integrations={integrations?.filter((integration) => integrationTemplate.vendor === integration.vendor)}
        integrationTemplate={integrationTemplate}
        isActionHidden={integrationTemplate.vendor === constants.INTEGRATIONS.GITLAB} // a temporary solution to hide action button for Gitlab integration
        isLoading={isLoading || (integrationTemplate.integrationType === IntegrationType.thirdParty && isLoadingParagon)}
        isNew={integrationTemplate.isNew}
        testId={`integrationCard-${integrationTemplate.vendor}`}
        title={integrationTemplate.label}
        variant={integrationTemplate.vendorType}
        vendor={integrationTemplate.vendor}
      />
    );
  };

  const shouldRenderTemplate = (template: IntegrationTemplate): boolean => template.integrationType === IntegrationType.secret
    || (template.integrationType === IntegrationType.thirdParty && !dynamicIntegrations.some((integration) => integration.vendor === template.key));

  const shouldRenderGroupedTemplate = (template: IntegrationTemplate): boolean => (
    template.integrationType !== IntegrationType.asCode || !!integrationFileLink?.vendorResponse?.htmlUrl
  );

  return (
    <div className={styles.wrapper}>
      <PageTitles subtitle='pages.integrations.subtitle' title='pages.integrations.title' />

      <div className={styles.page}>

        {isLoadingInstallation || (isLoading && !integrations) ? (
          <LoadingBar data-testid='loadingTestId' />
        ) : (

          !uiShowIntegrationSecurityTools ? (
            <div className={styles.integrationGroup}>
              <GithubIntegrationCard icon={GithubIcon} tags={getVariantTagArray(IntegrationVendorType.sourceCodeManagement)} title='vendor.github' />

              <AWSIntegrationCard icon={AwsIcon} tags={getVariantTagArray(IntegrationVendorType.cloud)} title='vendor.aws' />

              <SlackIntegrationCard icon={SlackIcon} tags={getVariantTagArray(IntegrationVendorType.notificationPlatform)} title='vendor.slack' />

              {dynamicIntegrations.map((dynamicIntegration) => {
                const integrationTemplate = integrationTemplates.find((template) => template.vendor === dynamicIntegration.vendor);
                return (
                  integrationTemplate && (
                    <IntegrationCard
                      key={dynamicIntegration.vendor}
                      data-testid={`integrationCard-${dynamicIntegration.vendor}`}
                      icon={integrationTemplate?.icon || dynamicIntegration.icon}
                      integrationFileLink={integrationFileLink?.vendorResponse?.htmlUrl}
                      integrations={integrations?.filter((integration) => dynamicIntegration.vendor === integration.vendor)}
                      integrationTemplate={integrationTemplate}
                      isLoading={isLoading}
                      variant={dynamicIntegration.variant}
                      vendor={dynamicIntegration.vendor}
                    />
                  )
                );
              })}

              {
                // filter out all the security tools integrations
                integrationTemplates.filter(
                  (integrationTemplate) => shouldRenderTemplate(integrationTemplate),
                ).map((integrationTemplate) => (
                  renderIntegrationCard(integrationTemplate)
                ))
              }
            </div>
          ) : (
            groupedTemplates.map((group) => (
              <div key={group.key}>
                <JitText className={styles.groupTitle} data-testid={`integration-group-title-${group.title}`} text={group.title} />

                <div className={styles.integrationGroup}>
                  {group.templates.map((integrationTemplate) => (
                    shouldRenderGroupedTemplate(integrationTemplate) && renderIntegrationCard(integrationTemplate)
                  ))}
                </div>
              </div>
            ))
          )

        )}
      </div>
    </div>
  );
};
